import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert';
import bigDecimal from 'js-big-decimal';

// Used to determine F1/Corvia theme, logo, and name variables
export const isCorvia = () => ((typeof window !== 'undefined' && !window.location?.hostname?.includes('f1payments')) || (typeof window === 'undefined'));

export const domainName = isCorvia() ? 'corviapay' : 'f1payments';
export const localUrl = `http://local.${domainName}.com:3000`;

export const useStubData = typeof window === 'undefined' || (typeof window === 'object' && window.location.hostname.includes(`local.${domainName}.com`));

const isProduction = () => window.location?.hostname === `www.${domainName}.com` ||
    window.location?.hostname === `${domainName}.com` ||
    window.location?.hostname === `www.crm.${domainName}.com` ||
    window.location?.hostname === `crm.${domainName}.com` ||
    window.location?.hostname === `portal.${domainName}.com`;
const isStage = () => window.location?.hostname?.includes('.stage.');
const isIntegration = () => window.location?.hostname?.includes('.test.');
const isDev = () => window.location?.hostname?.includes('.dev.');
const isLocalDev = () => typeof window === 'undefined' ||
  (window?.location?.hostname && window.location.hostname.includes(`local.${domainName}.`)) ||
  (window?.location?.hostname && window.location.hostname.includes(`localhost`));
// Used to determine if f1Payments or corviaPayments should be displayed
export const companyName = (option) => {
  switch (option) {
    case 'bothCaps':
      return isCorvia() ? 'CorviaPayments' : 'F1Payments';
    case 'firstCap':
      return isCorvia() ? 'Corviapayments' : 'F1payments';
    case 'allLower':
      return isCorvia() ? 'corviapayments' : 'f1payments';
    case 'lowerDash':
      return isCorvia() ? 'corvia-payments' : 'f1-payments';
    case 'camelCase':
      return isCorvia() ? 'corviaPayments' : 'f1Payments';
    case 'justFirst':
      return isCorvia() ? 'Corvia' : 'F1';
    default:
      return isCorvia() ? 'Corvia Payments' : 'F1 Payments';
  }
};

export const getEnvPrefix = () => {
  if (isProduction()) return '.';
  if (isStage()) return '.stage.';
  if (isIntegration()) return '.test.';
  if (isDev() || isLocalDev()) return '.dev.';
  return '.UNKNOWN_API_ENV.';
};

// Use the constants below to enable/disable features for testing, e.g.
// { envIsDevOrLess() && (<NewFeatureComponentThatDoesNotQuiteWorkYet />)}
export const envIsLocalOnly = () => isLocalDev();
export const envIsDevOnly = () => isDev();
export const envIsDevOrLess = () => envIsLocalOnly() || isDev();
export const envIsStageOnly = () => isStage();
export const envIsIntegrationOrLess = () => envIsDevOrLess() || isIntegration();
export const envIsIntegration = () => isIntegration();
export const envIsProd = () => isProduction();
export const envIsNotProd = () => envIsIntegrationOrLess() || isStage();

const isProdApiUrl = () => typeof window !== 'undefined' && getEnvPrefix() === '.';
const isStageApiUrl = () => typeof window !== 'undefined' && getEnvPrefix() === '.stage.';

export const apiUrl = () => {
  let activeUrl;
  if (typeof window !== 'undefined') {
    if (window.location.hostname === `www.${domainName}.com` ||
    window.location.hostname === `${domainName}.com` ||
    window.location.hostname === `portal.${domainName}.com` ||

    // if using base URL in crm (captcha, etc)
    window.location.hostname === `www.crm.${domainName}.com` ||
    window.location.hostname === `crm.${domainName}.com`
    ) {
      activeUrl = `https://api.${domainName}.com`;
    }
    if (window.location.hostname.includes('.stage.')) {
      activeUrl = `https://api.stage.${domainName}.com`;
    }
    if (window.location.hostname.includes('.test.')) {
      // integration url - for demo account
      activeUrl = `https://api.test.${domainName}.com`;
    }
    if (window.location.hostname.includes('.dev.')) {
      activeUrl = `https://api.dev.${domainName}.com`;
    }
    if (window.location.hostname.includes(`local.${domainName}.`)) {
      activeUrl = `https://api.dev.${domainName}.com`;
    }
  } else {
    /* istanbul ignore next */
    activeUrl = `https://api.dev.${domainName}.com`;
  }
  return activeUrl;
};
export const url = apiUrl();

// TODO: As this is in shared, we Should really rename this,
// only include shared endpoints, and if crm/portal use them, reference them from here.
// export const sharedEndpoint = { // api endpoints that are shared between projects
export const endpoint = { // api endpoints for the portal
  user: {
    root: `${url}/user`,
    signUp: `${url}/user/signUp`,
    verifyPhone: `${url}/user/verifyPhone`,
    sendPhoneVerification: `${url}/user/sendPhoneVerification`,
    verifyEmail: `${url}/user/verifyEmail`,
    sendEmailVerification: `${url}/user/sendEmailVerification`,
    signOut: `${url}/user/signOut`,
    signIn: `${url}/user/signIn`,
    forgotPasswordRequest: `${url}/user/requestPasswordResetCode`,
    forgotPasswordVerify: `${url}/user/resetPassword`,
    contact: `${url}/user/contact`,
    captcha: `${url}/user/captcha`
  },
  report: {
    root: `${url}/report`,
    cbCalculator: `${url}/report/cbCalculator`,
    achDetails: `${url}/report/achDetails`,
    achFunding: `${url}/report/achFunding`,
    achFundingSummery: `${url}/report/achFunding/summary`,
    applicationStatus: `${url}/report/applicationStatus/v2`,
    onHold: `${url}/report/merchant/onHold`,
    monthlySummary: `${url}/report/applicationStatus/monthlySummary`,
    merchantDetails: `${url}/report/merchant/detail/v2`,
    batchDetails: `${url}/report/batchDetails`,
    ledger: `${url}/report/ledger`,
    dispute: `${url}/report/dispute`,
    authorization: `${url}/report/authorization`,
    refund: `${url}/report/refund`,
    sales: `${url}/report/sales`,
    chargeback: `${url}/report/chargeback`,
    reserveDetail: `${url}/report/reserve/detail`,
    reserveCurrent: `${url}/report/reserve/current`
  },
  maintenance: {
    merchant: `${url}/maintenance/merchant`
  },
  merchant: {
    addTsysTerminal: `${url}/merchant/addTsysTerminal`,
    authTime: `${url}/merchant/landingPage/authTime`,
    authorizationResponse: `${url}/merchant/landingPage/authorizationResponse`,
    avsUsage: `${url}/merchant/landingPage/avsUsage`,
    dashboard: `${url}/merchant/landingPage`,
    download: `${url}/merchant/statement/download`,
    refund: `${url}/merchant/landingPage/refund`,
    submittingPartner: `${url}/merchant/submittingPartner`,
    delegate: `${url}/merchant/delegate/v2`,
    var: `${url}/merchant/var` // Common to Portal & MECO
  },
  parameter: {
    captchaSiteKey: `${url}/parameter/captchaSiteKey`,
    boardingEnabled: `${url}/parameter/boardingEnabled`,
    fifthThirdBankTerms: `${url}/parameter/fifthThirdBankTerms`,
    mvbTerms: `${url}/parameter/mvbTerms`,
    geolocationApiKey: `${url}/parameter/geolocationApiKey`
  },
  geocodedLocation: {
    geocodedLocation: `${url}/geocodedLocation`
  },
  delegateV2: `${url}/delegate/v2`,
  partner: {
    hierarchy: `${url}/partner/hierarchy`
  },
  partnerPortal: {
    actionReport: `${url}/partnerportal/actionReport`,
    landingPage: `${url}/partnerportal/landingPage`,
    income: `${url}/partnerportal/income`,
    notifications: `${url}/partnerportal/landingPage/notifications`,
    notificationBell: `${url}/partnerportal/notification/bell`,
    notificationConfig: `${url}/partnerportal/notification/config`,
    refund: `${url}/partnerportal/refund`,
    txns: `${url}/partnerportal/txns`,
    monthSummaryReport: `${url}/partnerportal/monthSummaryReport`,
    health: `${url}/partnerportal/landingPage/health`,
    ticket: `${url}/partnerportal/ticket`,
    externalCommunication: `${url}/partnerportal/ticket/externalCommunication`
  },
  log: `${url}/log`,
  verifi: { // Verifi endpoints for both CRM and Portal use
    root: `${url}/dispute/verifi`,
    details: `${url}/dispute/verifi/details`,
    notification: `${url}/dispute/verifi/notification`
  },
  crab: {
    v1: {
      application: {
        root: `${url}/creditAndBoarding/v1/application`,
        requiresNewSignatureFalse: `${url}/creditAndBoarding/v1/application/requiresNewSignatureFalse`,
        signatureType: `${url}/creditAndBoarding/v1/application/signatureType`,
        submit: `${url}/creditAndBoarding/v1/application/submit`,
        submitValidation: `${url}/creditAndBoarding/v1/application/submit/validate`,
        submitRescind: `${url}/creditAndBoarding/v1/application/submit/rescind`,
        task: `${url}/creditAndBoarding/v1/application/task`,
        taskConfig: `${url}/creditAndBoarding/v1/application/task/config`,
        tasks: {
          gdsRule: `${url}/creditAndBoarding/v1/application/task/gds/rule`,
          match: `${url}/creditAndBoarding/v1/application/task/match`,
          prohibitedEntities: `${url}/creditAndBoarding/v1/application/task/prohibitedEntities`,
          relatedPersons: `${url}/creditAndBoarding/v1/application/task/relatedPersons`,
          websiteReview: `${url}/creditAndBoarding/v1/application/task/websiteHtmlReview`,
          whoisReview: `${url}/creditAndBoarding/v1/application/task/whoisReview`,
          riskExposure: `${url}/creditAndBoarding/v1/application/task/riskExposure`
        },
        taskPend: `${url}/creditAndBoarding/v1/application/task/pend`,
        taskPendEmailList: `${url}/creditAndBoarding/v1/application/task/pend/emailList`,
        externalCommunication: `${url}/creditAndBoarding/v1/application/externalCommunication`,
        mpa: `${url}/creditAndBoarding/v1/application/mpa`,
        mpaCorrection: `${url}/creditAndBoarding/v1/application/mpa/correction`,
        mpaPdfCorrection: `${url}/creditAndBoarding/v1/application/mpa/correction/byPdf`,
        mpaDetail: `${url}/creditAndBoarding/v1/application/mpa/detail`,
        mpaMetadata: `${url}/creditAndBoarding/v1/application/mpa/metadata`,
        details: `${url}/creditAndBoarding/v1/application/detail`,
        template: `${url}/creditAndBoarding/v1/application/template`,
        templateDetail: `${url}/creditAndBoarding/v1/application/template/detail`,
        withdrawRequest: `${url}/creditAndBoarding/v1/application/withdrawRequest`,
        recallFromPartner: `${url}/creditAndBoarding/v1/application/recallFromPartner`,
        requestNewSignature: `${url}/creditAndBoarding/v1/application/submit/requestNewSignature`,
        escalate: `${url}/creditAndBoarding/v1/application/escalate`,
        submitToBank: `${url}/creditAndBoarding/v1/application/submitToBank`,
        relationshipReclassification: `${url}/creditAndBoarding/v1/application/relationshipReclassification`,
        toMpa: `${url}/creditAndBoarding/v1/application/toMpa`,
        sendForElectronicSignature: `${url}/creditAndBoarding/v1/application/sendForElectronicSignature`,
        assignEmployee: `${url}/creditAndBoarding/v1/application/assignEmployee`,
        negativeAction: `${url}/creditAndBoarding/v1/application/negativeAction/config`,
        pendReport: `${url}/creditAndBoarding/v1/application/report/pend`
      },
      file: {
        applicationPackage: `${url}/creditAndBoarding/v1/file/applicationPackage`,
        dummyDownloadUrl: `${url}/creditAndBoarding/v1/dummyDownloadUrl`
      },
      prevet: {
        root: `${url}/creditAndBoarding/v1/prevet`
      }
    },
    v3: {
      application: {
        approve: `${url}/creditAndBoarding/v3/application/approve`
      }
    },
    webform: {
      boarding: {
        v1: {
          application: `${url}/creditAndBoarding/webform/boarding/v1/application`,
          cacheUploadLink: `${url}/creditAndBoarding/webform/boarding/v1/cacheUploadLink`
        }
      }
    }
  },
  custom: {
    settings: `${url}/custom/settings`
  },
  nacha: {
    root: `${url}/nacha`,
    detail: `${url}/nacha/detail`,
    submitToBank: `${url}/nacha/submitToBank`,
    reject: `${url}/nacha/reject`,
    return: `${url}/nacha/return`
  },
  file: {
    v3DummyUploadUrl: `${url}/file/v3/dummyUploadUrl`,
    v3: {
      root: `${url}/file/v3`,
      tags: `${url}/file/v3/tags`,
      attachToResource: `${url}/file/v3/attachToResource`,
      cacheUploadLink: `${url}/file/v3/cacheUploadLink`
    }
  },
  portfolioSummaryReport: `${url}/portfolioSummaryReport`, // Common to Portal & MECO
  transactionProfitability: { // Common to Portal & MECO
    detail: `${url}/transactionProfitability/detail`,
    summary: `${url}/transactionProfitability/summary`
  }
};

// endpoints for Training
export const trainingEndpoint = {
  lessonPlan: `${url}/training/lessonPlan`,
  lessonPlanFile: `${url}/training/lessonPlan/file`,
  lessonPlanFileAttachToResource: `${url}/training/lessonPlan/file/attachToResource`
};

export const getRandomFromArray = arr => arr[Math.floor(Math.random() * arr.length)];

export const getRandomNumber = (options) => {
  const { min = 0, max = 20 } = options || {};
  return Math.floor(Math.random() * (max - min) + min);
};

export const monthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const stateCodes = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const monthNames = [
  'N/A', // default for 0 month
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const activeReports = [
  'authorization',
  'sales',
  'chargeback',
  'refund',
  'dispute'
];

// This is a list of all KNOWN id's that we never want to render in a DataTable
// Mostly this list is all our internal GUIDs
export const columnHideList = [
  'rowClass',
  'dataListItem',
  'achDepositId',
  'achFundingId',
  'achInfoId',
  'achRejectId',
  'actualResidualPayoutEntryId',
  'actualResidualPayoutId',
  'alertId',
  'applicationExternalCommunicationId',
  'applicationHistoryId',
  'applicationId',
  'applicationMpaId',
  'applicationNoteId',
  'applicationPendId',
  'applicationPendRoundId',
  'applicationTaskId',
  'applicationTaskNoteId',
  'applicationTemplateId',
  'applicationV1PendId',
  'assignedCreditEmployeeId',
  'assignedEmployeeId',
  'assignedOperationsEmployeeId',
  'authGridFeeId',
  'authorizationTransactionId',
  'batchStatusTrackerId',
  'cardId',
  'cardNumberId',
  'chargebackTransactionId',
  'childPartnerId',
  'contractId',
  'cronJobTrackerId',
  'disputeAlertId',
  'employeeGroupId',
  'employeeId',
  'experianResultId',
  'f1AchRequestId',
  'feeId',
  'fileId',
  'fileTagId',
  'financialTransactionId',
  'g2SyntheticCardId',
  'gdsResultId',
  'gdsRuleResultId',
  'giactId',
  'hrApplicationCloneId',
  'hrApplicationEinV1Id',
  'hrApplicationExternalCommunicationId',
  'hrApplicationId',
  'hrApplicationTaskId',
  'hrApplicationTaskNoteId',
  'hrApplicationV1Id',
  'hrApplicationV1NoteId',
  'hrApplicationV1TaskId',
  'lessonPlanId',
  'lrApplicationV1Id',
  'matchResultId',
  'merchantContactInfoId',
  'merchantDelegateId',
  'merchantEinId',
  'merchantEquipmentSummaryId',
  'merchantGuid',
  'merchantNoteId',
  'merchantPaymentSummaryId',
  'merchantPortalAccessId',
  'merchantPricingGridFeeId',
  'merchantStatementLastSuccessId',
  'mfcGridFeeId',
  'mpaFeeDetailId',
  'mpaTemplateFeeDetailId',
  'mpaTemplateId',
  'nmiAuthorizationTransactionId',
  'nmiGatewayId',
  'parentRelationshipId',
  'partnerContactInfoId',
  'partnerDelegateId',
  'partnerEinId',
  'partnerId',
  'partnerNoteId',
  'partnerPortalAccessId',
  'partnerResidualReportConfigId',
  'partnerResidualReportId',
  'pendId',
  'pendRoundId',
  'personId',
  'personPiiDobId',
  'personPiiId',
  'personPiiSsnId',
  'portalUserId',
  'prohibitedEntityId',
  'relationshipFeeDetailId',
  'relationshipId',
  'relationshipNoteId',
  'repayAuthorizationDetailFileId',
  'requestedByPortalUserId',
  'reserveId',
  'reservePayoutId',
  'resourceGuid',
  'riskAssessmentId',
  'riskCategoryId',
  'riskRuleId',
  'riskSubcategoryId',
  'settlementTransactionId',
  'statusId',
  'stickyContractValidationId',
  'taskId',
  'temporaryResidualId',
  'temporaryResidualRelationshipId',
  'ticketExternalCommunicationId',
  'ticketHistoryId',
  'ticketId',
  'ticketNoteId',
  'tsysLiveAuthorizationDetailFileId',
  'userDefinedGridFeeId',
  'varId'
];

export const dataTitles = (title) => {
  switch (title) {
    case 'relationshipCode':
      return 'Code';
    case 'relationshipId':
      return 'ID';
    case 'relationshipName':
      return 'Name';
    case 'Crm':
      return 'CRM';
    case 'Mcc Code':
      return 'MCC Code';
    case 'Relationship Id':
      return 'Relationship ID';
    case 'Address2':
      return 'Address 2';
    case 'Url':
      return 'URL';
    default:
      return camelToTitle(title);
  }
};

export const columnTitles = (title) => {
  switch (title) {
    case 'Dba Name':
    case 'Dba_name':
      return 'DBA Name';
    case 'Mid':
      return 'MID';
    case 'Avs Response Code':
      return 'AVS Response Code';
    case 'AV S Response Code':
      return 'AVS Response Code';
    case 'Cvv Presence Indicator':
      return 'CVV Presence Indicator';
    case 'CV V Presence Indicator':
      return 'CVV Presence Indicator';
    case 'Cvv Response':
      return 'CVV Response';
    case 'CV V Response':
      return 'CVV Response';
    case 'Ach Date':
      return 'ACH Date';
    case 'AC H Date':
      return 'ACH Date';
    case 'Ach Timestamp':
      return 'ACH Timestamp';
    case 'AC H Timestamp':
      return 'ACH Timestamp';
    case 'Transaction Date Cb':
      return 'Transaction Date';
    case 'Transaction Timestamp Cb':
      return 'Transaction Timestamp';
    case 'Authorization Code St':
      return 'Authorization Code';
    case 'Batch Id Ft':
      return 'Batch ID';
    case 'Transaction Date Auth':
      return 'Transaction Date';
    case 'Transaction Timestamp Auth':
      return 'Transaction Timestamp';
    case 'Transaction Id':
      return 'Transaction ID';
    case 'American Express':
      return 'Amex';
    case 'Mastercard':
      return 'MC';
    case 'Diner Club':
      return 'Diners Club';
    case 'Healthy MI Ds':
      return 'Healthy MIDs';
    case 'Unhealthy MI Ds':
      return 'Close to Closure';
    case 'Auth Access':
      return 'Authorization Access';
    case 'Ss Continuity':
      return 'SS Continuity';
    default:
      return title;
  }
};

export const capitalize = (s) => {
  if (isEmpty(s)) return '';
  if (typeof s !== 'string') return capitalize(`${s}`);
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const camelToTitle = (camelCase, options) => {
  const {
    fields = [],
    dataGroup = ''
  } = options || {};
  if (!isEmpty(fields) && !isEmpty(dataGroup)) { // use report headers
    const column = fields.find(({ key }) => key === camelCase);
    if (!isEmpty(column)) {
      return column.englishName;
    }
  }
  return columnTitles(
    camelCase
      .replace(/[0-9]{2,}/g, match => ` ${match} `)
      .replace(/[^A-Z0-9][A-Z]/g, match => `${match[0]} ${match[1]}`)
      .replace(/[A-Z][A-Z][^A-Z0-9]/g, match => `${match[0]} ${match[1]}${match[2]}`) // this causes the spacing
      .replace(/[ ]{2,}/g, match => ' ')
      .replace(/\s./g, match => match.toUpperCase())
      .replace(/^./, match => match.toUpperCase())
      .trim()
  );
};

export const snakeToTitle = (string, options) => {
  const { includeSpace = true } = options || {};
  if (isEmpty(string)) return '';
  const isAcronym = /^[A-Z]*$/.test(string) && string.length <= 3;
  if (isAcronym) return string;
  return string
    .toLowerCase()
    .split('_')
    .filter(word => !isEmpty(word))
    .map(word => (word.charAt(0).toUpperCase() + word.slice(1)))
    .join(includeSpace ? ' ' : '');
};

export const titleToSnakeBE = (string) => {
  if (isEmpty(string)) return '';
  return string
    .toUpperCase()
    .split(' ')
    .join('_');
};

export const errorTypes = {
  EvalError: `EvalError - An error occured regarding the global function eval()  WE SHOULD NEVER USE eval()`,
  RangeError: `RangeError - A number "out of range" has occurred`,
  ReferenceError: `ReferenceError - An illegal reference has occurred`,
  SyntaxError: `SyntaxError - A syntax error has occurred`,
  TypeError: `TypeError - A type error has occurred`,
  URIError: `URIError - An error in encodeURI() has occurred`
};

export const errorCodes = {
  /**
   * Generic error messages for all API calls
   * Codes that require specific messaging should be handled
   * in the specific API call request. Example when using `axiosRequest`
   * axiosRequest({
   *   // Standard request options
   *   errorOptions: {
   *     customMessageMap: { 409: 'Specific error message for 409' }
   *   }
   * })
   */
  400: `A bad request was sent. Please try again.`,
  401: `Invalid credentials. Please try again.`,
  402: `You cannot currently view the portal because you either turned off your rights to view it or because your payment method failed. Please contact Operations for further assistance.`, // payment failed or view rights removed
  403: `You do not have permission to access the requested resource.`,
  404: `The requested resource could not be found.`,
  // 408, 409 - should be handled in specific api calls
  418: `I'm a teapot (RFC 2324)`,
  422: `Unable to find resource ID. Please try again.`,
  426: `The latest version of this page was recently updated, please refresh the page to continue.`,
  428: `Please confirm your account.`,
  423: `API error. Please try again later.`,
  429: `Too many requests. Please try again in some time.`,
  '5xx': `An internal server error has occurred. Please try again.`,
  ERR_NETWORK: 'A network error has occurred. Please try again.', // Axios network error
  Unknown: `An unknown error has occurred. Please try again.`
};

export const getErrorMessage = (status, err, options) => {
  const { axiosRequest } = options || {}; // required
  if (isEmpty(status)) { // not an api error
    return errorCodes.Unknown;
  }
  if (errorCodes[status]) {
    return errorCodes[status];
  }
  const codeGroup = status.toString().replace(/\d{2}(?=\D*$)/g, 'xx');
  if (errorCodes[codeGroup]) {
    return errorCodes[codeGroup];
  }
  // Trigger error if it's a status that we are not handling
  const { response = {} } = err || {};
  const { config = {} } = response || {};
  const { method: apiReqMethod = '', url: apiReqUrl = '' } = config || {};
  const errOptions = !isEmpty(status) && !isEmpty(apiReqUrl) && !isEmpty(apiReqMethod)
    ? {
      logLevel: 'WARN',
      logMessage: `User received unexpected Unknown Error message for status code ${status} on ${apiReqMethod.toUpperCase()} call to ${apiReqUrl} - This error code should be handled on the frontend OR backend should send the error messages in the error response`
    }
    : null;
  !isEmpty(errOptions) && !isEmpty(axiosRequest) && triggerLogError({ ...errOptions, ...options });
  return `${errorCodes.Unknown} (Error ${status})`;
};

export const maskPhone = (s) => {
  if (isEmpty(s)) return '******';
  return s.replace(/\d(?=\d{4})/g, '*');
};

export const maskEmail = (s) => {
  if (isEmpty(s)) return '******';
  return s.replace(/^(.{1})[^@]+/g, '$1******');
};

export const piiNameList = [
  'dbaName',
  'controllingOwner',
  'beneficialOwners',
  'accountNumber',
  'bankAccountNumber',
  'cardNumber',
  'day',
  'dob',
  'ein',
  'month',
  'number',
  'password',
  'routingNumber',
  'ssn',
  'taxpayerIdentificationNumber',
  'year'
];

export const redactPiiFromResponse = (data) => {
  const tempData = data;
  if (typeof tempData === 'object') {
    if (tempData === null) return null;
    const propsList = Object.keys(tempData);
    propsList.forEach((prop) => {
      if (typeof tempData[prop] === 'object') {
        redactPiiFromResponse(tempData[prop]);
      } else if (piiNameList.find(element => element === prop)) {
        tempData[prop] = 'redacted';
      }
    });
  }
  return tempData;
};

export const redactPiiFromTitle = (title) => {
  const titleElements = title.split(' ');
  piiNameList.forEach((redactListElement, index) => {
    if (titleElements.find(findElementWithRedactEnding => findElementWithRedactEnding.includes(`.${redactListElement}`))) {
      const indexOfValue = titleElements.findIndex(findElementWithValue => findElementWithValue.includes('value='));
      titleElements[indexOfValue === -1 ? titleElements.length : indexOfValue] = 'value=redacted';
    }
  });
  return titleElements.join(' ');
};

export const errorTypeMessage = (err) => {
  let message;
  const uri = (typeof window !== 'undefined') ? window.location.href : '';
  const onPage = !isEmpty(uri) ? ` On Page: ${uri}` : '';
  if (err instanceof EvalError) {
    message = errorTypes.EvalError;
  } else if (err instanceof RangeError) {
    message = errorTypes.RangeError;
  } else if (err instanceof ReferenceError) {
    message = errorTypes.ReferenceError;
  } else if (err instanceof SyntaxError) {
    message = errorTypes.SyntaxError;
  } else if (err instanceof TypeError) {
    message = errorTypes.TypeError;
  } else if (err instanceof URIError) {
    message = errorTypes.URIError;
  } else {
    const { config = {} } = err;
    const { data = {} } = config;
    const replacementData = !isEmpty(data) ? redactPiiFromResponse(JSON.parse(data)) : {};
    const formattedErr = {
      ...err,
      config: {
        ...err.config,
        data: replacementData
      },
      response: {}
    };
    message = `${err.name || 'Unknown'} - An error of ${err.name || 'Unknown'} type has occured, ERROR: ${JSON.stringify(formattedErr)}`;
  }
  return `${message}${onPage}`;
};

export const errorHandler = (err, options) => {
  const {
    axiosRequest, // Required
    isPublicRequest // Optional - if token is NOT required to make api calls
  } = options || {};
  const { response = {} } = err || {};
  const { data = '' } = response || err;
  const { correlationId, errorMessageDetails } = getErrorCorrelation(err);
  const resStatus = getErrorStatusCode(err);
  const errorId = correlationId || getErrorId(data, resStatus);
  const message = Array.isArray(data?.error?.errorMessages)
    ? errorMessageDetails : getErrorMessage(resStatus, err, { axiosRequest, isPublicRequest });
  return `${message}${errorId}`;
};

export const getErrorCorrelation = (err) => {
  const { response } = err || {};
  const {
    data = {}, // or an array of error item objects
    status = null
  } = response || {};
  const { error = {} } = data || {};
  const { errorMessages = [], correlationId = '' } = error || {};
  // err.message exists if a custom Error object is passed
  // eg. err = new Error('this is a custom error message')
  const originalErrorMessageData = {
    ...(!isEmpty(errorMessages) && Array.isArray(errorMessages) && {
      errorMessagesArray: errorMessages
    })
  };
  if (Array.isArray(data) && !isEmpty(data)) {
    const newErrorMessages = data.map((item) => {
      if (item && item.message) {
        const key = `${item.key}: ` || '';
        return `${key}${key === 'ssn' ? 'redacted' : item.message}`;
      }
      return JSON.stringify(item).replace(/{|}|"/g, '');
    });
    return {
      correlationId,
      ...originalErrorMessageData,
      errorMessageDetails: newErrorMessages.join(newErrorMessages.length > 3 ? '\n' : ', ')
    };
  }
  if ((!isEmpty(errorMessages) && errorMessages.join(', ').includes('ssn')) || (!isEmpty(err?.message) && err.message.includes('ssn'))) {
    return {
      correlationId,
      ...originalErrorMessageData,
      errorMessageDetails: getErrorMessage(status, err)
    };
  }
  return {
    correlationId,
    ...originalErrorMessageData,
    errorMessageDetails: !isEmpty(errorMessages)
      ? errorMessages.join(', ')
      : err?.message || getErrorMessage(status, err)
  };
};

export const createErrorDetails = (errorMessage) => {
  // creates errorDetails Error object used in shared apiCall handler
  // for custom error cases that need to display an error to the user
  // so it behaves similar to an Error object
  let messageToDisplay = isEmpty(errorMessage) ? '' : errorMessage.slice();
  if (Array.isArray(errorMessage) && !isEmpty(messageToDisplay)) {
    messageToDisplay = messageToDisplay.join('\n');
  }
  return {
    errorDetails: new Error(messageToDisplay)
  };
};

export const getErrorId = (data, status) => {
  if (!isEmpty(data) && typeof data === 'string' && data.includes('Requestid=')) {
    // when BE sends a RequestId in the response body
    return ` (Request ID: ${data.match(/Requestid=(.*)/i)[1]})`;
  }
  return '';
};

export const isInViewport = (elem, options) => {
  const {
    fullVisibility = false,
    offsetX = 0,
    offsetY = 0
  } = options || {};
  let isVisible = false;
  let offLeft = false;
  let offRight = false;
  let offTop = false;
  let offBottom = false;
  let elemTop = 0;
  let elemBottom = 0;
  let elemLeft = 0;
  let elemRight = 0;
  if (!isEmpty(elem)) {
    const rect = elem.getBoundingClientRect();
    elemTop = rect.top + offsetY;
    elemBottom = rect.bottom + offsetY;
    elemLeft = rect.left + offsetX;
    elemRight = rect.right + offsetX;
    if (fullVisibility) {
      // Only if elem is completely visible
      isVisible = (elemTop >= 16) && (elemBottom <= window.innerHeight);
    } else {
      isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    }
    // also return what sides it is off
    offTop = elemTop < 0;
    offLeft = elemLeft < 0;
    offRight = elemRight > (window.innerWidth || document.documentElement.clientWidth);
    offBottom = elemBottom > (window.innerHeight || document.documentElement.clientHeight);
  }
  return {
    isVisible, elemTop, elemBottom, offTop, offBottom, offLeft, offRight
  };
};

export const dropdownDirection = (elem, options) => {
  const {
    footer = null
  } = options || {};
  let direction = false;
  const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  if (!isEmpty(elem)) {
    const inputWrapper = elem.parentElement;
    const wrapperTop = inputWrapper ? inputWrapper.getBoundingClientRect().top : 0;
    const elemTop = elem.getBoundingClientRect().top;
    const elemBottom = elem.getBoundingClientRect().bottom;
    const elemHeight = elem.getBoundingClientRect().height;
    let footerBottom = 0;
    let footerTop = 0;
    if (!isEmpty(footer)) {
      footerBottom = footer.getBoundingClientRect().bottom;
      footerTop = footer.getBoundingClientRect().top;
    } else {
      // -15 to account for scrollbar taking up window height
      footerBottom = windowHeight - 15;
    }
    const fitsAbove = wrapperTop > elemHeight;
    const fitsBelow = elemBottom < windowHeight && (
      !isEmpty(footer) ? (
        elemBottom < footerBottom &&
        (windowHeight < 800 ? (elemTop + elemHeight) < footerTop : true) &&
        (windowHeight >= 800 && window.pageYOffset < windowHeight
          ? (window.pageYOffset + elemBottom) < windowHeight
          : true)
      ) : true
    );
    if (elemHeight > windowHeight || (!fitsAbove && !fitsBelow)) {
      direction = 'full';
    } else {
      direction = fitsBelow ? 'down' : 'up';
    }
  }
  return direction;
};

export const comboboxDirection = (options) => {
  const {
    menu = null,
    menuHeader = null,
    useBlockForm = false
  } = options || {};
  const tabContentElems = document.querySelectorAll('.tabbedContent.content');
  const tabContentWrapper = !isEmpty(tabContentElems)
    ? Array.from(tabContentElems).find(dBox => dBox.contains(menu))
    : null;
  const minMenuHeight = 200; // to fit some options + search bar (for all browser heights)
  if (!isEmpty(tabContentWrapper) && !isEmpty(menuHeader)) {
    // If ComboBox is inside a TabContent component
    // (eg, meco crab -> GDS task card -> Risk exposure sub-card),
    // don't allow menu to overflow the component (going up),
    // or it will potentially cut off the menu list. Instead,
    // calculate the max menu height & menu direction using the TabContent body rect.
    const {
      height: tabContentHeight,
      bottom: tabContentBottom,
      top: tabContentTop
    } = tabContentWrapper.getBoundingClientRect();
    const {
      height: menuHeaderHeight,
      bottom: menuHeaderBottom,
      top: menuHeaderTop
    } = menuHeader.getBoundingClientRect();
    const {
      height: menuHeight
    } = menu.getBoundingClientRect();
    const maxMenuHeight = tabContentHeight - menuHeaderHeight;
    let newMenuHeight = maxMenuHeight > minMenuHeight
      // If max can fit more than min menu height, use max instead
      ? maxMenuHeight
      : menuHeight;
    const spaceBelow = Math.abs(tabContentBottom - menuHeaderBottom);
    const fitsBelow = spaceBelow >= newMenuHeight;
    const spaceAbove = Math.abs(tabContentTop - menuHeaderTop);
    const fitsAbove = spaceAbove >= newMenuHeight;
    if (fitsBelow || fitsAbove) {
      return {
        direction: fitsBelow ? 'bottom' : 'top',
        newHeight: newMenuHeight
      };
    }
    newMenuHeight = Math.max(spaceAbove, spaceBelow, minMenuHeight);
    return {
      direction: newMenuHeight === spaceAbove ? 'top' : 'bottom',
      newHeight: newMenuHeight
    };
  }
  const footer = document.querySelector('footer');
  const header = document.querySelector('#siteHeader.header');
  const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  let direction = 'bottom';
  let newHeight = -1;
  const wrapperOffset = useBlockForm ? 20 : 10;
  // for some reason, when you look at the top/bottom of the menu it is slightly
  // different than the bottom/top of the wrapper even though they exactly align
  // this accounts for that weird fudge factor
  const sidebarOpen = isSidebarOpen();
  const siteModalOpen = isSiteModalOpen();
  const isSidebarOrSiteModalOpen = sidebarOpen || siteModalOpen;
  const footerTop = (!isSidebarOrSiteModalOpen && !isEmpty(footer) &&
    footer.getBoundingClientRect().top < windowHeight
    ? footer.getBoundingClientRect().top
    : windowHeight) || windowHeight;
  // if footer is off edge of screen or doesn't exist, use general window height
  // otherwise, use footer as the bottom
  const headerBottom = (!isSidebarOrSiteModalOpen &&
    !isEmpty(header) && header.getBoundingClientRect().bottom) || 0;
  // header bottom is either the bottom of the header or the actual top of the page
  if (!isEmpty(menuHeader) && !isEmpty(menu)) {
    const wrapperTop = (menuHeader ? menuHeader.getBoundingClientRect().top : 0) - wrapperOffset;
    const wrapperBottom = (menuHeader
      ? menuHeader.getBoundingClientRect().bottom
      : 0) + wrapperOffset;
    const menuHeight = menu.getBoundingClientRect().height;
    const spaceUnder = footerTop - (wrapperBottom + menuHeight);
    // footer is the larger number, so if footer >=0 the menu can fit
    const fitsBelow = spaceUnder >= 0;
    const spaceAbove = wrapperTop - menuHeight - headerBottom;
    // wrapper top will be the largest number, so if you move upwards by menu heigh
    // and subtract away the header and the number is positive, you are above the header/top
    const fitsAbove = spaceAbove >= 0;
    if (!fitsAbove && !fitsBelow) {
      const moreRoomAboveForSmallerMenu = spaceAbove > spaceUnder;
      newHeight = menuHeight +
        (moreRoomAboveForSmallerMenu
          ? spaceAbove : spaceUnder);
      // resize the menu to fit in the largest space possible
      direction = moreRoomAboveForSmallerMenu ? 'top' : 'bottom';
    } else {
      direction = fitsBelow ? 'bottom' : 'top';
    }
  }
  return {
    direction,
    newHeight
  };
};

export const simulateClick = (elem) => {
  let event;
  if (isEmpty(elem)) return false;
  if (typeof MouseEvent === 'object') {
    event = document.createEvent('MouseEvent');
    event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  } else {
    event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
  }
  return !elem.dispatchEvent(event);
};

export const expandLinks = (array) => {
  if (isEmpty(array)) return [];
  const newLinks = [];
  array.forEach((link1) => {
    newLinks.push({ ...link1, type: 'parent' });
    if (link1.submenu) {
      link1.submenu.forEach((link2) => {
        newLinks.push({ ...link2, type: 'child' });
      });
    }
  });
  return newLinks;
};

export const pad = (n) => {
  const num = parseInt(n, 10);
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
};

export const formatPhone = (phone, options) => {
  const {
    pretty = false,
    includeCountryCode = false
  } = options || {};
  if (isEmpty(phone)) return '';

  // to display on FE
  if (pretty) return phone.toString().replace(/[^\d]+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');

  // by default, format for BE - NO non digit characters allowed
  const cleanPhone = phone.toString().replace(/\D/g, '');
  return `${includeCountryCode && cleanPhone.length === 10 ? '1' : ''}${cleanPhone}`;
};

export const decamelize = (str, separator) => {
  const sep = (isEmpty(separator) && separator !== ' ') ? '_' : separator;
  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${sep}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${sep}$2`)
    .toLowerCase();
};

export const cellActions = [
  'view',
  'edit',
  'download',
  'deleteDelegate',
  'deleteApp',
  'requestSignature',
  'select',
  'upload'
];

// Date/ Time Formatting
export const setPastMonth = (options) => {
  const {
    pastMonth = false,
    date = new Date(),
    months = 1
  } = options || {};
  const theDate = Object.prototype.toString.call(date) === '[object Date]' ? date : new Date(`${date}T00:00:00`);
  if (pastMonth) {
    theDate.setMonth(theDate.getMonth() - months);
    const from = new Date(theDate.getFullYear(), theDate.getMonth(), 1);
    const to = new Date(theDate.getFullYear(), theDate.getMonth() + months, 0);
    return {
      dateFrom: stringFromDateObj(from),
      dateTo: stringFromDateObj(to)
    };
  }
  const from = new Date(+theDate);
  const to = new Date(+theDate);
  const month = from.getMonth();
  // If still in same month, set date to last day of previous month
  from.setMonth(from.getMonth() - months);
  if (from.getMonth() === month) from.setDate(0);
  return {
    dateFrom: stringFromDateObj(from),
    dateTo: stringFromDateObj(to)
  };
};

export const stringFromDateObj = (date) => {
  const theDate = date instanceof Date ? date : new Date();
  theDate.setHours(0, 0, 0);
  theDate.setMilliseconds(0);
  const year = theDate.getFullYear();
  const month = (theDate.getMonth() + 1).toLocaleString('default', { month: '2-digit' });
  const day = theDate.toLocaleString('default', { day: '2-digit' });
  return `${year}-${pad(month)}-${pad(day)}`;
};

export const dateStringToDateObj = (string) => { // string format should be YYYY-MM-DD
  // string format should be YYYY-MM-DD
  let dateString = string;
  // in the event that the string is in mm/dd/yyyy format
  if (RegExp(/^\d{2}\/\d{2}\/\d{4}$/).test(string)) {
    const stringParts = string.split('/');
    dateString = `${stringParts[2]}-${stringParts[0]}-${stringParts[1]}`;
  }
  return new Date(dateString);
};

// string format should be YYYY-MM-DD
export const dateStringToIso = string => dateStringToDateObj(string).toISOString();

export const getLocaleString = (date, options) => {
  // converts date to user-friendly date (and optional time) MM/DD/YYYY HH:MM
  const { includeTime = true } = options || {};
  if (isEmpty(date)) return '';
  const dateObject = date instanceof Date
    ? date
    : new Date(date?.length === 10 && date?.includes('-') ? date.concat('T00:00:00.00') : date);
  const dateFormatted = dateObject.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includeTime && {
      timeZone: 'America/Chicago',
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit'
    })
  });
  // toLocaleString may return an invisible unicode space character
  // the below code will replace the non-standard space with a standard one
  return dateFormatted.replace(/\u202f/g, ' ');
};

export const getNow = (timestamp) => {
  const envPrefix = typeof window !== 'undefined' ? getEnvPrefix() : false;
  const useStaticDate = envPrefix === '.dev.' || envPrefix === '.test.';
  if (useStaticDate) {
    // use static date in dev (functional tests) & integration/test (demo account)
    return timestamp ? new Date(timestamp) : new Date('2020-08-02T14:35:16.950');
  }
  return timestamp ? new Date(timestamp) : new Date();
};

export const getThisYearMonth = (lastMonth = false) => {
  const now = getNow();
  const theMonth = new Date(now.getFullYear(), now.getMonth() - (lastMonth ? 1 : 0), 1);
  return stringFromDateObj(theMonth).replace(/-[^-]*$/, '');
};

export const getThisYearMonthDay = () => {
  const now = getNow();
  const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return stringFromDateObj(nowDate);
};

/**
 *
 * @param {*} monthsToAdd - Can be a negative number to get up to one year before the current date
 * @param {*} yearMonth - (Optional) date to start from. Will start from getNow() if not provided
 * @returns String in format 'YYYY-MM'
 */
export const addMonthsToYearMonth = (monthsToAdd, yearMonth) => {
  const targetDate = getNow();
  targetDate.setDate(1);
  if (!isEmpty(yearMonth)) {
    const dateObj = yearMonth.split('-');
    targetDate.setFullYear(dateObj[0]);
    // need go back one to convert date passed in to date Objects 0 based date
    targetDate.setMonth(parseInt(dateObj[1], 10) - 1);
  }
  targetDate.setMonth(targetDate.getMonth() + monthsToAdd);
  return `${targetDate.getFullYear()}-${targetDate.getMonth() < 10 ? '0' : ''}${targetDate.getMonth() + 1}`;
};

export const yearMonthToString = (yearMonth) => {
  const dateObj = yearMonth.split('-');
  return isEmpty(yearMonth) ? '' : ` ${monthsArray[parseInt(dateObj[1], 10) - 1]} ${dateObj[0]} `;
};

export const getPastMonths = (options) => {
  const {
    includeCurrentMonth = false // pass true to include current month + past 12 months
  } = options || {};
  const now = getNow();
  const theMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const monthList = [];
  const length = includeCurrentMonth ? 12 : 11;
  for (let i = 0; i <= length; i += 1) {
    monthList.push({
      title: `${monthsArray[theMonth.getMonth()]} ${theMonth.getFullYear()}`,
      value: stringFromDateObj(theMonth).replace(/-[^-]*$/, '')
    });
    theMonth.setMonth(theMonth.getMonth() - 1);
  }
  return monthList;
};

export const getCentralTime = (localDate) => { // Convert local time to CT
  const dateToFormat = !isEmpty(localDate)
    ? localDate
    // DO NOT use getNow() here, will cause an infinite loop on local
    : new Date(); // if no date is passed, will default to today
  const centralTimeString = getLocaleString(dateToFormat);
  const dateItems = !isEmpty(centralTimeString) ? centralTimeString.split(' ') : [];
  const time = !isEmpty(dateItems) ? dateItems[1] : '';
  const timeItems = !isEmpty(time) ? time.split(':') || [] : [];
  const [hours, minutes] = timeItems || [];
  const newDate = {
    date: centralTimeString,
    hours: !isEmpty(hours) ? Number(hours) : null,
    minutes: !isEmpty(minutes) ? Number(minutes) : null,
    isPM: dateItems.includes('PM') || false
  };
  return newDate;
};

export const getUserTimeZone = () => {
  const timeZone = new Date()
    .toLocaleDateString('en-US', { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g)
    .join('');
  return timeZone;
};

export const isPastTimestamp = (date, time) => { // date: YYYY-MM format, time: HH:MM format
  const inputDate = !isEmpty(date) && !isEmpty(time) ? new Date(`${date} ${time}`) : '';
  return !isEmpty(inputDate) ? inputDate.getTime() < Date.now() : false;
};

// formats any date into mm/dd/yyyy format
export const formatDateForFEView = (date, options) => {
  if (isEmpty(date)) {
    return '';
  }
  const { includeTime = false } = options || {};
  if (includeTime) { return getLocaleString(date, options); }
  let fullDate;
  // formats timestamps like yyyy-mm-ddThh:mm:ssZ
  if ((date).includes('T')) {
    const justDate = date.split('T')[0];
    fullDate = justDate.split('-');
    return `${fullDate[1]}/${fullDate[2]}/${fullDate[0]}`;
  }
  // formats timestamps like yyyy-mm-dd hh:mm:ssZ
  if ((date).includes(' ') && ((date).split(' ')).length === 2) {
    const justDate = date.split(' ');
    fullDate = justDate[0].split('-');
    return `${fullDate[1]}/${fullDate[2]}/${fullDate[0]}`;
  }
  // formats yyyy-mm-dd
  if ((date).includes('-')) {
    fullDate = date.split('-');
    return `${fullDate[1]}/${fullDate[2]}/${fullDate[0]}`;
  }
  return date;
};
// END Date/Time Formatting

export const ignoreCase = (text) => {
  const formatted = typeof text === 'string' ? text : JSON.stringify(text);
  if (isEmpty(formatted)) return '';
  return formatted.toLowerCase().trim();
};

export const toUpperCase = (text) => {
  if (typeof text === 'undefined' || text === null) { return ''; }
  const formatted = typeof text === 'string' ? text : JSON.stringify(text);
  return (formatted || '').toUpperCase().trim();
};

export const getTextContent = (elem) => {
  const elemType = getType(elem);
  if (isEmpty(elem) && !isBool(elem)) { return ''; }
  const defaultTypes = ['string', 'boolean', 'number', 'date'];
  if (defaultTypes.includes(elemType)) {
    return elemType === 'string' ? ignoreCase(elem || '') : elem;
  }
  if (elemType === 'object' && ['value', 'title', 'originalData'].some(v => (v in elem))) {
    const {
      value, tableDownloadValue, title, originalData
    } = elem || {};
    // If there is no value passed, check if there is a tableDownloadValue, and sort by that.
    const sortValue = value || tableDownloadValue;
    const valueToCheck = isBool(sortValue) ? sortValue : sortValue || ignoreCase(title || originalData || '');
    return getTextContent(valueToCheck);
  }
  // Else is React element stored as a variable
  const children = elem.props && elem.props.children;
  if (getType(children) === 'array') {
    return children.map(getTextContent).join('');
  }
  return getTextContent(children);
};

export const getDownloadValue = (colVal) => { // For downloading data in excel table format
  const valueType = getType(colVal);
  if (isEmpty(colVal) && !isBool(colVal)) { return ''; }
  const defaultTypes = ['string', 'boolean', 'number', 'date'];
  if (defaultTypes.includes(valueType)) {
    return colVal;
  }
  if (valueType === 'object' && ['tableDownloadValue', 'title', 'value'].some(v => (v in colVal))) {
    const { tableDownloadValue, title, value } = colVal || {};
    if (typeof tableDownloadValue !== 'undefined') { // custom-formatted table data
      return tableDownloadValue;
    }
    return isBool(value) ? value : title ?? value ?? null;
  }
  // Else is React element stored as a variable
  const { props } = colVal || {};
  const { children, checked } = props || {};
  if (isBool(checked)) { // Checkbox value
    return checked || null;
  }
  if (getType(children) === 'array') {
    return children.map(getDownloadValue)
      .filter(c => (!isEmpty(c) || isBool(c)))
      .join(', ');
  }
  if (getType(colVal) === 'array' && colVal[0]?.props) {
    // if the value itself is an array of nodes
    return colVal.map(getDownloadValue)
      .filter(c => (!isEmpty(c) || isBool(c)))
      .join(', ');
  }
  return getDownloadValue(children);
};

export const sortData = (array, sortByValue, options) => {
  const {
    sortByEmptyFirst = false,
    sortByEmptyLast = false,
    direction = 'asc'
  } = options || {};
  // sending in both sort by flags is an unsupported behavior and only sort by first will fire
  if (isEmpty(array)) return [];
  if (isEmpty(sortByValue)) return array;
  const emptyVals = ['-'];
  const sortingByDate = array.some((item) => {
    const textContent = getTextContent(item[sortByValue]);
    return (
      // FE timestamp
      RegExp(/^\d{2}\/?\d{2}\/?\d{4}, \d{1,2}:\d{2}/g).test(textContent) ||
      // MM/DD/YYYY or MM-DD-YYYY
      RegExp(/^[0-9]{2}[//-][0-9]{2}[//-][0-9]{4}$/).test(textContent) ||
      // YYYY-MM-DD
      RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/).test(textContent) ||
      // YYYY-MM
      RegExp(/^[0-9]{4}-[0-9]{2}$/).test(textContent)
    );
  });
  const sortingByPrice = array.some(item => (['$', '-$', '$-'].some(str => (`${getTextContent(item[sortByValue])}`).startsWith(str))));
  const isNumber = v => (!valueIsEmpty(v) && `${Math.sign(v)}` !== 'NaN');
  const valueIsEmpty = v => ((isEmpty(v) && !isBool(v)) || emptyVals.includes(v));
  const sortedData = array.sort((a, b) => {
    let first = !valueIsEmpty(a[sortByValue]) ? getTextContent(a[sortByValue]) : '';
    let second = !valueIsEmpty(b[sortByValue]) ? getTextContent(b[sortByValue]) : '';
    if (sortingByDate) {
      const firstDate = new Date(first);
      const secondDate = new Date(second);
      // convert to ISO string to properly sort by the date and time
      first = Date.parse(firstDate) ? firstDate.toISOString() : first;
      second = Date.parse(secondDate) ? secondDate.toISOString() : second;
    } else if (sortingByPrice) {
      const cleanPrice = v => (!valueIsEmpty(v) ? `${v}` : '').replace(/\$|,/g, '');
      first = cleanPrice(first);
      second = cleanPrice(second);
      const firstIsNumber = isNumber(first);
      const secondIsNumber = isNumber(second);
      if (firstIsNumber || secondIsNumber) {
        first = firstIsNumber ? Number(first) : first;
        second = secondIsNumber ? Number(second) : second;
        if (firstIsNumber && valueIsEmpty(second)) { second = first - 1; }
        if (secondIsNumber && valueIsEmpty(first)) { first = second - 1; }
      }
    } else if (isNumber(first) || isNumber(second)) {
      first = isNumber(first) ? Number(first) : first;
      second = isNumber(second) ? Number(second) : second;
    }
    if (first > second) {
      return 1;
    }
    if (first < second) {
      return -1;
    }
    return 0;
  });
  if (sortByEmptyFirst || sortByEmptyLast) {
    const empty = sortedData.filter(d => isEmpty(d[sortByValue]) ||
      emptyVals.includes(d[sortByValue]));
    const notEmpty = sortedData.filter(d => !isEmpty(d[sortByValue]) &&
      !emptyVals.includes(d[sortByValue]));
    if (!isEmpty(empty)) {
      if (sortByEmptyFirst) {
        const data = direction === 'desc'
          ? empty.concat(notEmpty.reverse())
          : notEmpty.concat(empty);
        return data;
      } if (sortByEmptyLast) {
        const data = direction === 'desc'
          ? notEmpty.reverse().concat(empty)
          : notEmpty.concat(empty);
        return data;
      }
    }
  }
  return direction === 'desc' ? sortedData.reverse() : sortedData;
};

export const getDataKeys = (data, lines = null, label) => {
  const labelText = !isEmpty(label) ? label.toString() : '';
  if (isEmpty(data)) return [];
  const oldData = isEmpty(data.data);
  const newData = oldData ? data : data.data;
  const currentData = newData[1] || newData[0];
  const keys = Object.keys(currentData).filter(key => key !== 'label' && key !== labelText);
  if (lines) {
    const hasOnlyLineData = JSON.stringify([...lines].sort()) === JSON.stringify([...keys].sort());
    !hasOnlyLineData && lines.forEach((key) => {
      if (keys.indexOf(key) > -1) {
        keys.splice(keys.indexOf(key), 1);
      }
    });
  }
  return keys;
};

export const generateRandColor = (brightness) => {
  // Six levels of brightness darkest- 0,1,2,3,4,5 -lightest
  const rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
  const mix = [brightness * 51, brightness * 51, brightness * 51]; // 51 => 255/5
  const mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(
    x => Math.round(x / 2.0)
  );
  return {
    rgb: `rgb(${mixedrgb.join(',')})`,
    hex: generateHex(mixedrgb[0], mixedrgb[1], mixedrgb[2])
  };
};

export const getMerchantColorMap = (merchantGuidToDba) => {
  const merchantMap = !isEmpty(merchantGuidToDba)
    ? Object.values(merchantGuidToDba).reduce((acc, m) => ({ ...acc, [m]: '' }), {})
    : {};
  return !isEmpty(merchantMap) ? setColors({ data: [merchantMap] }) : {};
};

export const getRelationshipAndMerchantColorMap = (relationshipArray, options) => {
  const { prevMap } = options || {};
  const nameMap = !isEmpty(relationshipArray)
    ? relationshipArray.reduce((acc, relationship) => {
      const { downlines, merchantGuidToDba, relationshipName } = relationship || {};
      const merchantMap = getMerchantColorMap(merchantGuidToDba);
      const currentMap = { ...prevMap, ...merchantMap, [relationshipName]: '' };
      const mergedMap = !isEmpty(downlines)
        ? getRelationshipAndMerchantColorMap(downlines, { prevMap: currentMap })
        : { ...currentMap };
      return { ...acc, ...mergedMap };
    }, {})
    : {};
  return !isEmpty(nameMap) ? setColors({ data: [nameMap] }) : {};
};

export const setColors = (data, lines, label, options) => {
  const {
    existingColors = {},
    mirrorColors = []
  } = options || {};
  const dataKeys = getDataKeys(data, lines, label);

  const hardCodedColors = {
    volume: '#004942', // corvia forest green
    count: '#a88a42', // Corvia gold
    ratio: '#36af9e',
    total: '#23326b',
    // credit card branded colors
    americanExpress: '#2770cf',
    discover: '#f56203',
    mastercard: '#f79e1c',
    visa: '#1a1f71',
    other: '#4cd7cf'
  };
  const storedColors = {
    ...hardCodedColors,
    ...existingColors
  };
  dataKeys.map(async (key) => {
    const newColor = { ...generateRandColor(2) };
    const newColorHex = newColor.hex;
    if (!Object.prototype.hasOwnProperty.call(storedColors, key)) {
      storedColors[key] = newColorHex;
    }
    if (!isEmpty(mirrorColors)) {
      // only ever used in cases where we shift color in graphs that have specific negative data
      // currently, only the onboarding performance graph does this
      mirrorColors.forEach((colorSet) => {
        if (key.includes(colorSet.base)) { // test INCLUDES, not exact, since these keys are complex
          storedColors[key.replace(colorSet.base, colorSet.target)] =
            colorBlender('#ffffff', storedColors[key], 50);
        } else if (!key.includes(colorSet.target)) {
          storedColors[key] = storedColors[key];
        }
      });
    }
    return true;
  });
  return storedColors;
};

export const roundIt = (value, options) => {
  const {
    type = 'round', // floor, ceil, round, trunc
    precision = null
  } = options || {};
  if (precision !== null) {
    const multiplier = 10 ** precision;
    return Math[type](value * multiplier) / multiplier;
  }
  return Math[type]((value + Number.EPSILON) * 100) / 100;
};

export const formatNumber = (num, options) => {
  if (isEmpty(num)) return '';
  const {
    currency = false,
    unrounded = false,
    abbreviate = false,
    round = false,
    fixed = false
  } = options || {};
  let number = num;
  if (round) {
    number = Math.round(number);
  }
  const isNeg = Math.sign(number) === -1;
  const sign = isNeg ? '-' : '';
  const absNum = Math.abs(number);
  const newNum = isNeg ? absNum : number;
  if (abbreviate) {
    if (absNum < 1e3) number = newNum;
    if (absNum >= 1e3 && absNum < 1e6) number = `${+(newNum / 1e3).toFixed(1)}K`;
    if (absNum >= 1e6 && absNum < 1e9) number = `${+(newNum / 1e6).toFixed(1)}M`;
    if (absNum >= 1e9 && absNum < 1e12) number = `${+(newNum / 1e9).toFixed(1)}B`;
    if (absNum >= 1e12) number = `${+(newNum / 1e12).toFixed(1)}T`;
    if (currency) {
      return `${sign}$${number}`;
    }
    if (typeof number === 'number') return isNeg ? -Math.abs(number) : number;
    return `${sign}${number}`;
  }
  if (currency) {
    // only reason we would not round currency is when displaying a per-item interchange cost.
    return unrounded
      ? `${sign}$${newNum}`
      : `${sign}$${bigDecimal.round(newNum, 2, bigDecimal.RoundingModes.CEILING).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
  if (fixed) {
    number = parseFloat(number).toFixed(2);
  }
  return number < 1000 ? number : number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isEmpty = (obj) => {
  // Only to be used for function returns, objects (arrays), strings, and numbers
  // Sending booleans will just return what was sent, not if it has a value or not
  if (obj === null || obj === undefined) return true;
  if (obj.constructor === Boolean && !obj) return true;
  if (obj.constructor === Number && Number.isNaN(obj)) return true;
  if (obj.constructor === String && obj.replace(/\s/g, '').length === 0) return true;
  if (obj.constructor === Array && obj.length === 0) return true;
  if (obj.constructor === Object) {
    return Object.keys(obj).length === 0;
  }
  return false;
};

export const isBool = (value) => {
  switch (typeof value) {
    case 'boolean':
      return true;
    case 'string':
      return value === 'true' || value === 'false';
    default:
      return false;
  }
};

export const sanitizeHTML = (str) => {
  const temp = document.createElement('div');
  temp.textContent = isEmpty(str) ? '' : str;
  return temp.innerHTML;
};

export const customizedTooltip = (data) => {
  const {
    value,
    name,
    biaxial = '',
    currency = false,
    biaxialCurrency = false
  } = data || {};
  const biaxialId = Array.isArray(biaxial) ? biaxial[0] : biaxial;
  const newValue = name === biaxialId
    ? formatNumber(value, { currency: biaxialCurrency })
    : formatNumber(value, { currency });
  return newValue;
};

export const stringToRef = (object, reference, options) => {
  const {
    delimiter = '.'
  } = options || {};
  let refIsValid = true;
  const arrDeref = (o, ref, i) => {
    if (!ref) { return o; }
    const index = ref.slice(0, i ? -1 : ref.length);
    refIsValid = !!o[index];
    return o[index] ?? [];
  };
  const dotDeref = (o, ref) => (`${ref}`.split('[').reduce(arrDeref, o));
  const arr = !reference ? object : (reference || '').split((delimiter || '.'));
  const results = (arr || []).reduce(dotDeref, object);
  return refIsValid ? results : undefined;
};

export const nestedFind = (arr, keys, value, options) => {
  // Deep search an array of objects for given key that matches given value.
  if (isEmpty(arr) || isEmpty(keys) || isEmpty(value)) return [];
  const {
    ignoreKey = null,
    ignoreValue = null,
    removeDuplicates = true,
    sortBy = {
      title: null,
      value: null // should be set to the primary key name of the option (eg. 'guid')
    }
  } = options || {};
  const matchesFound = [];
  const findIt = (a, k, v) => {
    a.map((sub) => {
      const nK = ignoreCase(sub[k]);
      const nV = ignoreCase(v);
      const matchAlreadyFound = removeDuplicates
        ? !isEmpty(
          matchesFound.find(match => match[sortBy.value || k] === sub[sortBy.value || k])
        )
        : false;
      if (
        !isEmpty(nK) &&
        nK.includes(nV) &&
        (sub.type && ignoreKey && sub[ignoreKey] !== ignoreValue) &&
        !matchAlreadyFound
      ) {
        matchesFound.push(sub);
      } else {
        Object.keys(sub).map((item) => {
          const subItem = !isEmpty(sub[item]) ? sub[item] : '';
          if (subItem.constructor === Array) {
            findIt(subItem, k, value);
          }
          return true;
        });
      }
      return true;
    });
  };
  keys.forEach((key) => {
    findIt(arr, key, value);
  });
  return !isEmpty(sortBy.title) ? sortData(matchesFound, sortBy.title) : matchesFound;
};

export const chartHeader = {
  currency: false,
  biaxialCurrency: false,
  height: 200,
  showLegend: false,
  stacked: false,
  negatives: false,
  label: null,
  biaxial: null,
  lines: [],
  legendPreText: null,
  colors: null
};

export const getParams = (options) => {
  const {
    getApiParams = false, // only passed when getting params from API URL
    apiParams = '', // only passed when getting params from API URL; can be a string or object
    allowDuplicates = false
  } = options || {};
  const allParams = allowDuplicates ? [] : {};
  if (getApiParams) {
    // converts the uri params in a string or object format into an object with key/value pairs
    const output = typeof apiParams === 'string' ? allParams : apiParams;
    if (typeof apiParams === 'string') { // eg. ?pageIndex=0&pageSize=10
      const urlParams = new URLSearchParams(apiParams);
      const convertVal = (val) => {
        const isBoolean = isBool(val);
        // handle Number(val) === 0 as it does not parse correctly
        const isNumber = Number(val) || Number(val) === 0;
        return isBoolean || isNumber ? JSON.parse(val) : val;
      };
      urlParams.forEach((value, key) => {
        output[key] = convertVal(value);
      });
    }
    // if params is not a string, it should already be in the expected format
    // eg. { pageIndex: 0, pageSize: 10 }
    return isEmpty(output) ? {} : output;
  }
  // getting params from the browser URL
  let hash;
  const uriFragment = window.location.hash;
  const urlString = !isEmpty(uriFragment)
    ? window.location.href.split(uriFragment)[0]
    : window.location.href;
  const hashes = urlString
    .slice(urlString.indexOf('?') + 1)
    .split('&');
  for (let i = 0; i < hashes.length; i += 1) {
    hash = hashes[i].split('=');
    const [key, val] = hash;
    if (hash.length > 1) { // only has length of 1 if there's no value
      allowDuplicates ? allParams.push({ [key]: val }) : allParams[key] = val;
    }
  }
  if (!allowDuplicates && !isEmpty(uriFragment)) {
    /**
     * If a fragment exists, send it back with the params.
     * Currently NOT supported if `allowDuplicates` is used
     */
    allParams.uriFragment = `${uriFragment}`;
  }
  return allParams;
};

export const scrollToComponent = (selector, options) => {
  const { callback, offsetTopSelector } = options || {};
  window.requestAnimationFrame(() => {
    const elem = document.querySelector(`${selector}`);
    if (elem) {
      const { top: elemTop } = elem ? elem.getBoundingClientRect() : {};
      const offsetTopElem = document.querySelector(`${offsetTopSelector}`);
      const {
        height: offsetTopHeight
      } = offsetTopElem ? offsetTopElem.getBoundingClientRect() : {};
      const targetpos = elemTop + window.scrollY - 82 - (offsetTopHeight ?? 0);
      const fixedPosition = targetpos.toFixed();
      const onScroll = () => {
        if (window.scrollY.toFixed() === fixedPosition) {
          window.removeEventListener('scroll', onScroll);
          // if scroll is done, run any provided callbacks
          callback && callback();
        }
      };
      window.addEventListener('scroll', onScroll);
      onScroll();
      window.scrollTo({ top: targetpos, behavior: 'smooth' });
    }
  });
};

export const getTextDimensions = (elem, options) => {
  const {
    offset = 12
  } = options || {};
  let testElem = elem;
  let text;
  if (elem instanceof HTMLInputElement) {
    if (elem.type === 'text' || elem.type === 'number') {
      text = elem.value;
    }
  } else if (typeof elem === 'string') {
    testElem = document.createElement('span');
    testElem.innerHTML = elem;
    text = elem;
  }
  const fontWeight = window.getComputedStyle(testElem, null).getPropertyValue('font-weight');
  const fontSize = window.getComputedStyle(testElem, null).getPropertyValue('font-size');
  const fontFamily = window.getComputedStyle(testElem, null).getPropertyValue('font-family');
  let tdcBox = document.querySelector('.tdcBox');
  if (!tdcBox) {
    tdcBox = document.createElement('span');
    tdcBox.setAttribute('class', 'tdcBox');
    tdcBox.style.display = 'inline-block';
    tdcBox.style.position = 'absolute';
    tdcBox.style.zIndex = '-1';
    tdcBox.style.fontWeight = fontWeight;
    tdcBox.style.fontFamily = fontFamily;
    tdcBox.style.fontSize = fontSize;
    document.body.appendChild(tdcBox);
  }
  tdcBox.textContent = text;
  const dimensions = {
    width: tdcBox.offsetWidth + offset,
    height: tdcBox.offsetHeight
  };
  tdcBox.parentNode.removeChild(tdcBox);
  return dimensions;
};

export const getPathname = () => window.location.pathname.substring(1);

export const fileTagValues = [
  // standard tags - employees and partners can see/edit
  { value: 'photo_id', title: 'Photo ID' },
  { value: 'signed_mpa', title: 'Signed MPA' },
  { value: 'voided_check_or_bank_letter', title: 'Voided Check/Bank Letter' },
  { value: 'merchant_info_form', title: 'Merchant Info Form' },
  { value: 'terminal_order_form', title: 'Terminal Order Form' },
  { value: 'processing_statements', title: 'Processing Statements' },
  { value: 'bank_statements', title: 'Bank Statements' },
  { value: 'merchant_statement', title: 'Merchant Statement' },
  { value: 'merchant_1099', title: '1099 (Merchant)' },
  { value: 'partner_1099', title: '1099 (Partner)' },
  { value: 'other', title: 'Other' },
  { value: 'reserve_form', title: 'Reserve Form' },
  { value: 'attestation', title: 'Attestation' },
  { value: 'customer_receipt_email_template', title: 'Customer Receipt Email Template' },
  { value: 'cancellation_confirmation_email_template', title: 'Cancellation Confirmation Email Template' },
  { value: 'unsuccessful_auth_receipt_form_template', title: 'Unsuccessful Auth Receipt Form Template' },
  { value: 'explicit_consent_form_template', title: 'Explicit Consent Form Template' },
  { value: 'maintenance_page_screenshot', title: 'Maintenance Page Screenshot' },
  { value: 'nob_addendum', title: 'NOB Addendum' },
  { value: 'nutra_questionnaire', title: 'Nutra Questionnaire' },
  { value: 'product_country_compliance', title: 'Product Country Compliance' },
  { value: 'corvia_reserve_form', title: 'Corvia Reserve Form' },
  { value: 'outside_legal_review', title: 'Outside Legal Review' },
  { value: 'business_financials', title: 'Business Financials' },
  { value: 'business_license', title: 'Business License' },
  { value: 'articles_of_incorporation', title: 'Articles Of Incorporation' },
  { value: 'ss4', title: 'SS-4 Form' },
  { value: 'fulfillment_agreement', title: 'Fulfillment Agreement' },
  { value: 'customer_service_agreement', title: 'Customer Service Agreement' },
  { value: 'crm_agreement', title: 'CRM Agreement' },
  { value: 'chargeback_agreement', title: 'Chargeback Agreement' },
  { value: 'netevia_reserve_form', title: 'Netevia Reserve Form' },
  { value: 'partner_agreement', title: 'Partner contract/agreement' },
  { value: 'partner_w9', title: 'W-9 Form' },
  { value: 'schedule_a_b', title: 'Schedule A/B' },
  { value: 'ach_authorization', title: 'ACH Authorization Form' },

  // users CANNOT edit - system-generated (only employees can see)
  { value: 'uploaded_by_partner', title: 'Partner-Added', isFixed: true },
  { value: 'uploaded_by_employee', title: 'Employee-Added', isFixed: true },
  { value: 'uploaded_by_system', title: 'System-Added', isFixed: true },
  { value: 'electronic_mpa', title: 'Electronic MPA', isFixed: true },
  { value: 'residual_output', title: 'Residual Output', isFixed: true },
  { value: 'repay_raw_file', title: 'Repay Raw File', isFixed: true },
  { value: 'residual_input', title: 'Residual Input File', isFixed: true },
  { value: 'schedule_a', title: 'Schedule A', isFixed: true },
  { value: 'charge_type_config', title: 'Charge Type Config File', isFixed: true },
  { value: 'create_preparation_config', title: 'Create Preparation Config File', isFixed: true },
  { value: 'standard_computation_input_config', title: 'Standard Computation Input Config File', isFixed: true },
  { value: 'compute_residual_calculation_config', title: 'Compute Residual Calculation Config File', isFixed: true },
  { value: 'prework_template', title: 'Prework Template', isFixed: true },
  { value: 'residuals_compute_residual_calculation', title: 'Compute Residual Calculation', isFixed: true },
  { value: 'compute_bank_validation_config', title: 'Compute Bank Validation Config File', isFixed: true },
  { value: 'residuals_bank_validation', title: 'Residual Bank Validation', isFixed: true },
  { value: 'residual_partner_labeling_config', title: 'Residual Partner Labeling Config File', isFixed: true },
  { value: 'residuals_generate_partner_report', title: 'Residual Generate Partner Report', isFixed: true },
  { value: 'standard_computation_input', title: 'Standard Computation Input', isFixed: true },
  { value: 'standard_computation_output_template', title: 'Standard Computation Output Template', isFixed: true },
  { value: 'residual_partner_output_template', title: 'Residual Partner Output Template File', isFixed: true },
  { value: 'residual_calculation_report', title: 'Residual Calculation Report File', isFixed: true },
  { value: 'create_preparation_file', title: 'Residual Create Preparation File', isFixed: true },
  { value: 'missing_mids_and_mpas', title: 'Residual Missing MIDs/MPAs', isFixed: true },

  // employees can edit/see, partners can only see
  { value: 'giact_report', title: 'GIACT Report', isInternal: true },
  { value: 'match_report', title: 'MATCH Report', isInternal: true },
  { value: 'experian_report_individual', title: 'Experian Report (Individual)', isInternal: true },
  { value: 'experian_report_business', title: 'Experian Report (Business)', isInternal: true },
  { value: 'residual_input', title: 'Residual Input', isInternal: true },
  { value: 'fee_file', title: 'Fee File', isInternal: true },
  { value: 'notice_of_adverse_action', title: 'Notice Of Adverse Action', isInternal: true },
  { value: 'kyc_site_scan_results', title: 'KYC Site Scan Results', isInternal: true },

  // Task tags - employees can edit/see, partners can only see
  { value: 'kyb_know_your_business', title: 'KYB (Know Your Business) Task', isInternal: true },
  { value: 'kyc_know_your_customer', title: 'KYC (Know Your Customer) Task', isInternal: true },
  { value: 'banking_validation', title: 'Banking Validation Task', isInternal: true },
  { value: 'gds', title: 'GDS Rule Review Task', isInternal: true },
  { value: 'match', title: 'MATCH Task', isInternal: true },
  { value: 'owner_credit_bureau_data', title: 'Owner Credit Bureau Data Task', isInternal: true },
  { value: 'dba_credit_bureau_data', title: 'DBA Credit Bureau Data Task', isInternal: true },
  { value: 'illicit_activity_review', title: 'Illicit Activity Review Task', isInternal: true },
  { value: 'prohibited_entities', title: 'Prohibited Entities Task', isInternal: true },
  { value: 'ofac', title: 'OFAC Task', isInternal: true },
  { value: 'mpa_validation', title: 'MPA Validation Task', isInternal: true },
  { value: 'mpa_post_new_signature_verification', title: 'MPA Post New Signature Verification Task', isInternal: true },
  { value: 'additional_document_review', title: 'Additional Document Review Task', isInternal: true },
  { value: 'related_person', title: 'Related Persons Task', isInternal: true },
  { value: 'risk_score', title: 'Risk Score Task', isInternal: true },
  { value: 'corvia_documents_review', title: 'Corvia Documents Review Task', isInternal: true },
  { value: 'due_diligence_review', title: 'Due Diligence Review Task', isInternal: true },
  { value: 'negative_option_billing_requirements', title: 'Negative Option Billing Requirements Task', isInternal: true },
  { value: 'business_financial_review', title: 'Business Financial Review Task', isInternal: true },
  { value: 'risk_exposure', title: 'Risk Exposure Task', isInternal: true },
  { value: 'whois_review', title: 'Whois Review Task', isInternal: true },
  { value: 'website_html_review', title: 'Website HTML Review Task', isInternal: true },

  // hey you modifying this code, are you adding an employee group?
  // if so, please check out this PR to make sure you are adding it everywhere needed
  // BIRB 6957. Please add in all places that PR adds to

  // employee groups - only employees can edit/see, partners cannot see
  { value: 'employee_group_engineering', title: 'Engineering', isInternal: true },
  { value: 'employee_group_operations_legacy', title: 'Operations (Legacy)', isInternal: true },
  { value: 'employee_group_operations_manager', title: 'Operations Manager', isInternal: true },
  { value: 'employee_group_credit', title: 'Credit', isInternal: true },
  { value: 'employee_group_app_review', title: 'App Review', isInternal: true },
  { value: 'employee_group_app_review_manager', title: 'App Review Manager', isInternal: true },
  { value: 'employee_group_risk', title: 'Risk', isInternal: true },
  { value: 'employee_group_risk_manager', title: 'Risk Manager', isInternal: true },
  { value: 'employee_group_human_resources', title: 'Human Resources', isInternal: true },
  { value: 'employee_group_cross_river', title: 'Cross River', isInternal: true },
  { value: 'employee_group_fifth_third', title: 'Fifth Third', isInternal: true },
  { value: 'employee_group_synovus', title: 'Synovus', isInternal: true },
  { value: 'employee_group_cynergy', title: 'Cynergy', isInternal: true },
  { value: 'employee_group_vimas', title: 'Vimas', isInternal: true },
  { value: 'employee_group_wells_fargo', title: 'Wells Fargo', isInternal: true },
  { value: 'employee_group_operations', title: 'Operations', isInternal: true },
  { value: 'employee_group_analytics', title: 'Analytics', isInternal: true },
  { value: 'employee_group_sales', title: 'Sales', isInternal: true },
  { value: 'employee_group_it', title: 'IT', isInternal: true },
  { value: 'employee_group_compliance', title: 'Compliance', isInternal: true },
  { value: 'employee_group_risk_closure_admin', title: 'Risk Closure Admin', isInternal: true },
  { value: 'employee_group_product', title: 'Product Group', isInternal: true },
  { value: 'employee_group_project', title: 'Project Group', isInternal: true },
  { value: 'employee_group_marketing', title: 'Marketing', isInternal: true },
  { value: 'employee_group_mvb', title: 'MVB', isInternal: true },
  { value: 'employee_group_residual', title: 'Residual Group', isInternal: true },
  { value: 'employee_group_esquire', title: 'Esquire', isInternal: true },
  { value: 'employee_group_internal_employees', title: 'All Internal Employees', isInternal: true },
  { value: 'employee_group_all_employees', title: 'All Employees', isInternal: true }
];

export const monthsDropdown = [
  {
    title: `Jan`,
    value: 'JANUARY'
  },
  {
    title: `Feb`,
    value: 'FEBRUARY'
  },
  {
    title: `Mar`,
    value: 'MARCH'
  },
  {
    title: `Apr`,
    value: 'APRIL'
  },
  {
    title: `May`,
    value: 'MAY'
  },
  {
    title: `Jun`,
    value: 'JUNE'
  },
  {
    title: `Jul`,
    value: 'JULY'
  },
  {
    title: `Aug`,
    value: 'AUGUST'
  },
  {
    title: `Sep`,
    value: 'SEPTEMBER'
  },
  {
    title: `Oct`,
    value: 'OCTOBER'
  },
  {
    title: `Nov`,
    value: 'NOVEMBER'
  },
  {
    title: `Dec`,
    value: 'DECEMBER'
  }
];

export const monthToIsoNumber = {
  JANUARY: '01',
  FEBRUARY: '02',
  MARCH: '03',
  APRIL: '04',
  MAY: '05',
  JUNE: '06',
  JULY: '07',
  AUGUST: '08',
  SEPTEMBER: '09',
  OCTOBER: '10',
  NOVEMBER: '11',
  DECEMBER: '12'
};

export const defaultInputErrors = {
  phone: 'Please enter a valid phone number, including country code and area code. Example format: 1-555-555-5555',
  password: [
    'Password must contain at least 10 characters',
    'Password must contain a number',
    'Password must contain a special character',
    'Password must contain an upper case letter',
    'Password must contain a lower case letter'
  ]
};

export const useMockDbData = csrfToken => ( // For FTs/testing locally using mock data only
// Must use this for all mock data `csrfToken` values
  (envIsDevOrLess() && csrfToken === 'mockFrontendCsrfToken') || false
);

export const setLocalToken = csrfToken => (useMockDbData(csrfToken) && localStorage.setItem('localToken', csrfToken));

export const logAsWarn = (err = {}, options) => {
  const {
    response = {},
    message = '',
    stack = '',
    code = ''
  } = err || {};
  const {
    errorLogLevels = { info: [], warn: [], error: [] },
    customLogMessage = null
  } = options || {};
  const errorNames = [ // name-specific errors we want to reduce to a warn
    'Network Error',
    'timeout of 0ms exceeded',
    'Request aborted',
    'ChunkLoadError',
    'removeChild'
  ];
  const chunkErrorRegex = [
    /(Loading chunk )\d+( failed)/,
    /(Loading CSS chunk )\d+( failed)/
  ];
  const newStatus = getErrorStatusCode(err);
  const convertedStatus = JSON.stringify(newStatus).replace(/\d{2}(?=\D*$)/g, 'xx'); // replaces last 2 digits of status with xx
  const errorString = JSON.stringify(err).concat(JSON.stringify(options));
  const errorTitle = customLogMessage || message || JSON.stringify(response.data);
  const isChunkError = chunkErrorRegex.some(c => errorString.match(c));
  const reduceToWarn = !isEmpty(errorLogLevels.warn) && Array.isArray(errorLogLevels.warn)
    ? errorLogLevels.warn.includes(convertedStatus)
    : false;
  return (
    isChunkError || errorNames.some(v => (stack && stack.includes(v)) ||
    (errorTitle && errorTitle.includes(v)) || errorString.includes(v))
  ) || reduceToWarn || code === 'ECONNABORTED';
};

export const triggerLogError = (options) => {
  // To manually trigger an error that we want the FE to log
  const {
    logLevel = '', // optional - one of INFO, WARN, ERROR. default is ERROR
    logMessage = '', // message to be logged. BE filters out special chars besides _ and -
    user = {}, // app-specific `user` object from redux `store.authenticate`
    axiosRequest, // app-specific `axiosRequest` util
    isPublicRequest, // Optional - if token is NOT required to make api calls
    createCsrfHeader = null // app-specific util `createCsrfHeader`
  } = options || {};
  if (!isEmpty(logMessage)) {
    const token = createCsrfHeader instanceof Function ? createCsrfHeader() : null;
    const { identityToken = {} } = user || {};
    const userEmail = identityToken.email || localStorage.getItem('userEmail') || 'user_not_signed_in';
    const uri = (typeof window !== 'undefined') ? window.location.href : '';
    const err = new Error();
    const errorOptions = {
      logLevel: logLevel || 'ERROR',
      // Need to include `userEmail` in log in case there is no token being sent
      customLogMessage: `ON_PAGE ${uri} -- USER ${userEmail} -- FRONTEND_ERROR_DETAILS - ${logMessage}`,
      token,
      userEmail
    };
    logError(err, { ...errorOptions, axiosRequest, isPublicRequest });
  }
};

export const getErrorStatusCode = (err) => {
  const { response } = err || {};
  const errorStatusCode = ((response && renderIfObject(response.status)) || (err && err.code)) || 'NO';
  return errorStatusCode;
};

export const logError = async (err, options) => {
  // Used to send any FE error we want to the BE logs
  // only log errors in production
  // Do NOT log if the error is the log api itself.
  const isLogger = !!(err.response && err.response.config &&
    err.response.config.url === endpoint.log);
  const isProdOrStage = isProdApiUrl() || isStageApiUrl();
  const {
    requestGuidDetails = null,
    missingRequiredToken = false,
    isPublicRequest = false,
    token = {},
    userEmail = null,
    logLevel: customLogLevel = '',
    customLogMessage = null,
    errorLogLevels = {
      info: [],
      warn: ['4xx'],
      error: [500, 'NO', 'Unknown']
    },
    axiosRequest
  } = options || {};
  if (!isLogger && isProdOrStage) {
    const {
      response,
      config,
      message,
      stack
    } = err;
    let logLevel;
    let errorMessage;
    const logErrorAsWarn = logAsWarn(err, { ...options, errorLogLevels });
    const errorType = errorTypeMessage(err);
    const metric = {
      metricName: '',
      count: 1
    };
    let moreErrorDetails = '';
    const errDetails = `${err}` === '[object Object]' ? JSON.stringify(err) : err;
    if (config) {
      // it's an api error
      const method = (config && config.method && config.method.toUpperCase()) || 'UNKNOWN URL';
      const requestUrl = config && config.url;
      const errorTitle = (response && response.data) || message || 'UNKNOWN ERROR';
      const formattedErrorTitle = redactPiiFromTitle(`${getType(errorTitle) === 'object' ? JSON.stringify(errorTitle) : errorTitle}`);
      const statusCode = getErrorStatusCode(err);
      const email = !isEmpty(config.data) ? JSON.parse(config.data).email || userEmail : userEmail;
      const user = !isEmpty(email) ? ` User: ${email} received` : '';
      const configHeaders = config && config.headers;
      const headerData = !isEmpty(configHeaders) ? ` Headers: ${JSON.stringify(configHeaders)}` : '';
      const stackTrace = !isEmpty(stack) ? `Stack: ${stack}` : '';
      const { correlationId = '', errorMessageDetails = '' } = getErrorCorrelation(err);
      moreErrorDetails = [
        ...(!isEmpty(requestGuidDetails) ? [`REQUEST GUID DETAILS: ${requestGuidDetails}`] : []),
        ...(!isEmpty(correlationId) ? [`ERROR CORRELATION ID: ${correlationId}, ERROR DETAILS: ${errorMessageDetails}`] : [])
      ].join(', ');
      if (errorLogLevels.info.includes(statusCode)) {
        logLevel = 'INFO';
      } else if (logErrorAsWarn) {
        logLevel = 'WARN';
        if (errorTitle === 'Network Error') {
          // BE requested that we send this metric for network errors
          metric.metricName = 'network_error';
        }
      } else if (
        errorLogLevels.error.includes(statusCode) ||
        (statusCode >= 500 && statusCode < 600) // Server error
      ) {
        logLevel = 'ERROR';
      }
      errorMessage = customLogMessage || `${logLevel}: ${formattedErrorTitle},${errorType} USER: ${user} ${statusCode} STATUS CODE from ${method} call to: ${requestUrl}${headerData} ${stackTrace}`;
    } else if (logErrorAsWarn) {
      logLevel = 'WARN';
      errorMessage = customLogMessage || `${errorType} USER: ${userEmail} ${errDetails}`;
    } else {
      const user = !isEmpty(userEmail) ? ` User: ${userEmail} received` : '';
      const stackTrace = !isEmpty(stack) ? ` Stack: ${stack}` : '';
      logLevel = 'ERROR';
      errorMessage = customLogMessage || `${errorType} USER: ${user} ${errDetails}${stackTrace}`;
    }
    const body = {
      logLevel: !isEmpty(customLogLevel) ? customLogLevel : logLevel,
      timestamp: (new Date()).toISOString(),
      messageID: `frontend-api-error ${uuidv4()}`,
      message: `${errorMessage}${moreErrorDetails}`,
      ...(!isEmpty(metric.metricName) && { metric })
    };
    if (!isLogger) { // DO NOT LOG if it's the logger api
      const originalRequestError = !isEmpty(config)
        ? {
          method: config.method || 'UNKNOWN',
          url: config.url || 'UNKNOWN',
          status: getErrorStatusCode(err)
        }
        : errDetails;
      const apiRes = await axiosRequest({
        url: endpoint.log,
        method: 'put',
        fullPageLoad: false,
        tokenRequired: !!(!isEmpty(token) && !isEmpty(token['x-csrf-token'])),
        ...((missingRequiredToken || isPublicRequest) && { tokenRequired: false }),
        errorOptions: { ...options, originalRequestError, isErrorLogger: true }
      }, body);
      const { errorDetails = null } = apiRes || {};
      return errorDetails instanceof Error ? errorDetails : apiRes;
    }
  }
  return false;
};

export const logMetric = async (metName, options) => {
  // Used to send any FE metric we want to the BE logs
  // only logs metrics in production
  // currently these metrics are only triggered on the partner portal
  const isProd = isProdApiUrl();
  const {
    axiosRequest,
    token = {}
  } = options || {};
  if (isProd) {
    let metricMessage;
    if (metName === 'report_filter' || metName === 'dashboard_filter') {
      metricMessage = `Info Metric: user changed the ${metName} for their data on the partner portal`;
    }
    const body = {
      logLevel: 'INFO',
      timestamp: (new Date()).toISOString(),
      messageID: `frontend-metric ${uuidv4()}`,
      message: `${metricMessage}`,
      metric: {
        metricName: metName,
        count: 1
      }
    };
    const apiRes = await axiosRequest({
      url: endpoint.log,
      method: 'put',
      fullPageLoad: false,
      // currently only logging authenticated request metrics
      ...(!isEmpty(token) && {
        tokenRequired: true
      })
    }, body);
    const { errorDetails = null } = apiRes || {};
    return errorDetails instanceof Error ? errorDetails : apiRes;
  }
  return false;
};

export const pageVisibilityApi = () => { // to support older browser-specific api
  let hidden = null;
  let visibilityChange = null;
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
  return { hidden, visibilityChange };
};

export const boolToString = (value, options = {}) => {
  const { capitalize: shouldCapitalize } = options || {};
  const stringValue = value ? 'yes' : 'no';
  return shouldCapitalize ? capitalize(stringValue) : stringValue;
};

export const toCamelCase = (string, separator) => {
  const camelCaseName = string.toLowerCase().split(separator);
  if (camelCaseName.length > 1) {
    const wordArr = [camelCaseName[0]];
    for (let i = 1; i < camelCaseName.length; i += 1) {
      const capitalName = camelCaseName[i].charAt(0).toUpperCase() + camelCaseName[i].slice(1);
      wordArr.push(capitalName);
    }
    return wordArr.join('');
  }
  return camelCaseName.join('');
};

export const createPartnerTree = tree => sortData(tree
  .reduce((acc, partner) => [...acc]
    .concat(createRelationship(partner)), []), 'dba');

const createRelationship = (partner) => {
  if (isEmpty(partner)) return {};
  const {
    relationshipId,
    relationshipName,
    relationshipCode,
    riskProfile,
    bankName,
    processorName,
    parentRelation,
    crabConfigurationOptions,
    childPartner,
    downlines,
    payingChild,
    showToChild,
    merchantGuidToDba,
    inactive
  } = partner || {};
  const children = isEmpty(downlines)
    ? []
    : sortData(downlines
      .reduce((acc, downline) => [...acc]
        .concat(createRelationship(downline)), []), 'dba');
  const formattedMerchants = !isEmpty(merchantGuidToDba)
    ? getGuidToDba(merchantGuidToDba, partner).map(m => ({
      ...m,
      bankName: ignoreCase(bankName || ''),
      processName: ignoreCase(processorName || ''),
      riskProfile: ignoreCase(riskProfile || ''),
      childPartner
    }))
    : [];
  const currentRelationshipMerchants = !isEmpty(formattedMerchants)
    ? sortData(formattedMerchants, 'dba')
    : [];
  // count all merchants under this relationship + all downline merchants
  const totalMerchantCount = !isEmpty(children)
    ? getTotalMerchantCount(children, currentRelationshipMerchants.length)
    : currentRelationshipMerchants.length;
  const bank = !isEmpty(bankName)
    ? toCamelCase(bankName, '_')
    : 'Unknown Bank';
  const processor = !isEmpty(processorName)
    ? toCamelCase(processorName, '_')
    : 'Unknown Processor';
  const bankNameEnum = !isEmpty(bankName) ? ignoreCase(bankName) : '';
  const processorNameEnum = !isEmpty(processorName) ? ignoreCase(processorName) : '';
  return {
    guid: relationshipId, // partner id
    dba: !isEmpty(relationshipName) ? relationshipName : 'Unknown Name',
    type: 'subPartner',
    parent: {
      guid: parentRelation,
      type: 'subPartner'
    },
    crabConfigurationOptions,
    bank,
    bankName: bankNameEnum,
    processor,
    processorName: processorNameEnum,
    subPartner: children,
    merchant: currentRelationshipMerchants,
    totalMerchantCount,
    relationshipCode,
    riskProfile: riskProfile ? ignoreCase(riskProfile) : null,
    childPartner,
    payingChild,
    showToChild,
    inactive: inactive || false
  };
};

export const getTotalMerchantCount = (downlines, total) => downlines.reduce((acc, downline) => {
  const { merchant, subPartner } = downline || {};
  const hasMerchants = !isEmpty(merchant);
  const hasSubPartners = !isEmpty(subPartner);
  if (hasMerchants || hasSubPartners) {
    if (hasSubPartners) {
      const runningTotalMerchants = acc + (hasMerchants ? merchant.length : 0);
      return getTotalMerchantCount(subPartner, runningTotalMerchants);
    }
    return acc + (hasMerchants ? merchant.length : 0);
  }
  return acc;
}, total);

// flattens all downlines' merchant list
export const getAllMerchants = (downlines, totalMerchants = []) => downlines
  .reduce((acc, downline) => {
    const { merchant, subPartner } = downline || {};
    const hasMerchants = !isEmpty(merchant);
    const hasSubPartners = !isEmpty(subPartner);
    if (hasMerchants || hasSubPartners) {
      if (hasSubPartners) {
        const runningTotalMerchants = hasMerchants ? acc.concat(merchant) : acc;
        return getAllMerchants(subPartner, runningTotalMerchants);
      }
      return hasMerchants ? acc.concat(merchant) : acc;
    }
    return acc;
  }, totalMerchants);

export const getGuidToDba = (list, parent) => {
  if (isEmpty(list)) return [];
  return sortData(Object.entries(list)
    .reduce((acc, [guid, value]) => {
      if (typeof value === 'string') { // just dba name
        const legalName = (parent && parent.merchantGuidToLegalName)
          ? parent.merchantGuidToLegalName[guid] : null;
        const mid = (parent && parent.merchantGuidToMid)
          ? parent.merchantGuidToMid[guid] : null;
        const businessContactName = (parent && parent.merchantGuidToBusinessContactName)
          ? parent.merchantGuidToBusinessContactName[guid] : null;
        return [...acc].concat({
          guid,
          dba: value,
          type: 'merchant',
          ...(legalName && { legalName }),
          ...(mid && { mid }),
          ...(businessContactName && { businessContactName }),
          ...(parent ? {
            parent: {
              guid: parent.relationshipId,
              dba: !isEmpty(parent.relationshipName) ? parent.relationshipName : 'Unknown Name'
            },
            relationshipId: parent.relationshipId,
            partnerId: parent.childPartner
          } : {
            parent: undefined
          })
        });
      }
      // handle merchant access list
      const { dbaName, merchantPortalAccess } = value;
      return [...acc].concat({
        merchantGuid: guid,
        dba: dbaName,
        ...(parent ? {
          parent: {
            guid: parent.relationshipId,
            dba: !isEmpty(parent.relationshipName) ? parent.relationshipName : 'Unknown Name'
          },
          relationshipId: parent.relationshipId,
          partnerId: parent.childPartner
        } : {
          parent: undefined
        }),
        ...merchantPortalAccess
      });
    }, []), 'dba');
};

export const getBooleanOrRadio = (value = null, options) => {
  // converts value to boolean value (eg, for BE) or radio value (eg, for forms)
  const {
    defaultValue = null, // if value is empty but we want to set a default value
    toBool = false,
    toRadio = false
  } = options || {};
  if (toBool) { // convert a radio value to a boolean
    if (ignoreCase(value) === 'no') { return false; }
    if (ignoreCase(value) === 'yes') { return true; }
  } else if (toRadio) { // convert a boolean to a radio value
    const isBoolean = isBool(value);
    if (isBoolean) {
      const newVal = typeof value === 'string' && (ignoreCase(value) === 'true' || ignoreCase(value) === 'false')
        ? JSON.parse(value)
        : value;
      return newVal ? 'yes' : 'no';
    }
  }
  return defaultValue !== null && (isEmpty(value) || !isBool(value))
    ? defaultValue
    : value;
};

// override axios defaults
export const includeXsrfHeaders = {
  // do NOT change key names - these are the keys expected by axios
  xsrfCookieName: 'Csrf-token', // the name of the cookie that BE returns
  xsrfHeaderName: 'X-Csrf-Token' // the name that the BE expects in headers
};

// indicates whether or not cross-site Access-Control requests should be made using credentials
export const includeWithCredentials = {
  withCredentials: true
};

export const isValidUrl = (value, options) => {
  const { requireProtocol = false } = options || {};
  if (requireProtocol) {
    return RegExp(/https?:\/\/(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/g).test(value);
  }
  return RegExp(/^(?:(http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}?(\/.*)?$/g).test(value);
};

export const downloadFile = (uri, fileName) => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = uri;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return true;
};

export const transformData = (options) => {
  const {
    data,
    toSchema,
    template,
    version = '1.0'
  } = options || {};
  if (isEmpty(data)) {
    return data; // return back the empty array, object, whatever it was.
  }
  return template[toSchema](data, version);
};

export const getQueryParamString = (array, options) => {
  const { includePrefix = true } = options || {};
  // converts uri query params to a string for api calls
  const params = array.reduce((acc, option, i) => {
    const prefix = i === 0 && includePrefix ? '?' : '&';
    if (!isBool(option.value) && isEmpty(option.value)) return acc;
    return acc.concat(`${prefix}${option.key}=${option.value}`);
  }, '');
  return params;
};

export const sharedGetInnerAlertBarState = (options) => {
  /**
   * ONLY USE this method for shared components.
   * For crm/web, use utils.getInnerAlertBarState
   */
  // when using an alert bar inside of a component, this returns the state
  // needed to trigger the inner alert bar
  const {
    axiosRequest, // required
    isPublicRequest, // Optional - if token is NOT required to make api calls
    type = 'closed',
    data = ''
  } = options || {};
  let alertBarMessage = data;
  if (type === 'warning') {
    alertBarMessage = typeof data === 'string' ? data : errorHandler(data, { axiosRequest, isPublicRequest });
  }
  return {
    alertBarType: type,
    alertBarMessage,
    spinnerLoading: false
  };
};

export const getUrlObject = (endpointUrl) => {
  // returns an object containing the url (with no query params) and the query string
  const urlValues = endpointUrl.includes('?') ? endpointUrl.split('?') : false;
  if (urlValues) {
    const urlWithNoParams = urlValues[0];
    return {
      urlWithNoParams,
      queryStringParams: urlValues[1]
    };
  }
  return {
    urlWithNoParams: endpointUrl,
    queryStringParams: null
  };
};

export const getType = (obj) => {
  switch (typeof (obj)) {
    // object prototypes
    case 'object':
      if (obj instanceof Array) { return 'array'; }
      if (obj instanceof Date) { return 'date'; }
      if (obj instanceof RegExp) { return 'regexp'; }
      // With eslint, we don't allow the creation of object primitives like string, number and bool
      // but added to method for thouroughness
      if (obj instanceof String) { return 'string'; }
      if (obj instanceof Number) { return 'number'; }
      if (obj instanceof Boolean) { return 'boolean'; }
      return 'object';
    default:
      // object literals
      return typeof (obj);
  }
};

const areArraysEqual = (obj1, obj2) => {
  if (obj1.length !== obj2.length) return false;
  const notEqual = obj1.some((o1, i) => !isEqual(obj1[i], obj2[i]));
  return !notEqual;
};

const areObjectsEqual = (obj1, obj2) => {
  // need to pass new objects to recursiveley travers nested objects.
  if (obj1 === null || obj2 === null) {
    // check for null, since it's an object
    // if they are BOTH null, they are equal
    if (obj1 === obj2) return true;
  }
  const obj1Keys = Object.keys(obj1 || {});
  const obj2Keys = Object.keys(obj2 || {});
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  if (obj1Keys.includes('$$typeof')) { // React elements
    /**
     * If a component is passed as an array item, its type will be "object" (instead of "symbol").
     * In this case, DON'T do a deep equality check, as this will cause an infinite loop,
     * instead just compare the text content.
     */
    const textContent1 = getTextContent(obj1);
    const textContent2 = getTextContent(obj2);
    const sameTextContent = isEqual(textContent1, textContent2);
    return sameTextContent;
  }
  const notEqual = obj1Keys.some(
    key => Object.prototype.hasOwnProperty.call(obj1, key) && !isEqual(obj1[key], obj2[key])
  );
  return !notEqual;
};

export const isEqual = (obj1, obj2) => {
  // compare two anythings to see if they are equal
  // arrays, object, primitives, functions, etc...
  const type = getType(obj1);
  // If the two items are not the same type, return false
  if (type !== getType(obj2)) return false;
  // Now just compare based on type
  if (type === 'array') return areArraysEqual(obj1, obj2);
  if (type === 'symbol') { // React elements
    return (obj1 ?? '').toString() === (obj2 ?? '').toString();
  }
  if (type === 'object') return areObjectsEqual(obj1, obj2);
  if (type === 'function') return obj1.toString() === obj2.toString();
  if (Number.isNaN(obj1) && Number.isNaN(obj2)) {
    return true;
  }
  return obj1 === obj2;
};

export const renderIfObject = obj => (getType(obj) === 'object' ? `OBJ: ${JSON.stringify(obj).replace(/(")+/g, ' ')}` : obj);

export const uploadCacheFilesToS3 = async (filesList, options) => {
  /**
   * 1. Gets upload link (to S3 cache bucket) for each file (GET /file/v3/cacheUploadLink)
   * 2. Uploads each file that successfully received cache upload link to S3 (PUT /<>uploadLink<>)
   * 3. Returns all success data, errors with cache uploading and/or errors loading to s3
   */
  const {
    axiosRequest,
    isPublicRequest, // Optional - if token is NOT required to make api calls
    cacheUploadEndpoint,
    filePreloadTemplate,
    templateVersion,
    requiredFiles,
    useUploadErrorSwal,
    updateState,
    useSpinner
  } = options || {};
  const useInnerSpinner = useSpinner ?? !isEmpty(updateState);
  const cacheUploadOptions = {
    axiosRequest,
    isPublicRequest,
    cacheUploadEndpoint,
    useSpinner: useInnerSpinner,
    updateState
  };
  const filesToCache = !isEmpty(filesList)
    ? filesList.map(f => ({
      ...f,
      // Add `fileName` or `name` properties if missing
      ...(isEmpty(f.fileName) && !isEmpty(f.name) && { fileName: f.name }),
      ...(isEmpty(f.name) && !isEmpty(f.fileName) && { name: f.fileName })
    }))
    : [];
  const { results } = !isEmpty(filesToCache)
    ? await handleCacheUploads(filesToCache, cacheUploadOptions)
    : {};
  const { filesSuccessResults, filesErrorResults } = results || {};
  const filesWithS3Key = !isEmpty(filesSuccessResults)
    ? filesSuccessResults.reduce((acc, successRes) => {
      const fileMatch = !isEmpty(successRes.fileName)
        ? filesToCache.find(f => isEqual(f.name, successRes.fileName))
        : {};
      const formattedSuccessFile = {
        ...fileMatch,
        ...successRes,
        fileOptions: { s3Key: successRes.s3Key },
        ...(!isEmpty(fileMatch) && {
          backendKey: fileMatch.backendKey,
          fileData: fileMatch.data
        })
      };
      return !isEmpty(fileMatch) ? acc.concat(formattedSuccessFile) : acc;
    }, [])
    : [];
  const s3UploadFiles = !isEmpty(filesWithS3Key)
    ? transformData({ // Format upload files to PUT to S3
      data: {
        responseArray: filesWithS3Key,
        requiredFilesList: !isEmpty(requiredFiles) ? requiredFiles : filesWithS3Key || []
      },
      toSchema: 'frontend',
      template: filePreloadTemplate,
      version: !isEmpty(templateVersion) ? templateVersion : '1.0'
    })
    : [];
  useInnerSpinner && updateState({ spinnerLoading: true });
  const { s3SuccessFiles, s3ErrorFiles } = !isEmpty(s3UploadFiles)
    ? await handleLoadedFiles({
      includeFileErrors: true,
      axiosRequest,
      isPublicRequest,
      encodedFiles: filesWithS3Key,
      uploadFiles: s3UploadFiles,
      hideFullPageAlertBar: true
    })
    : { s3SuccessFiles: [], s3ErrorFiles: [] };
  useInnerSpinner && updateState({ spinnerLoading: false });
  const filesLoadedToS3 = !isEmpty(s3SuccessFiles)
    ? filesWithS3Key.reduce((acc, fileWithS3Key) => {
      const s3UploadFile = s3SuccessFiles.find(
        s3SuccessFile => isEqual(s3SuccessFile.fileName, fileWithS3Key.fileName)
      );
      return !isEmpty(s3UploadFile) ? acc.concat(fileWithS3Key) : acc;
    }, [])
    : [];
  const cacheAndS3ErrorFiles = [
    ...(!isEmpty(filesErrorResults) ? filesErrorResults : []),
    ...(!isEmpty(s3ErrorFiles) ? s3ErrorFiles : [])
  ];
  const uploadData = {
    originalFilesToUpload: filesToCache,
    cacheAndS3ErrorFiles,
    filesWithS3Key: filesLoadedToS3 // Successful cache files uploaded to s3
  };
  useUploadErrorSwal && !isEmpty(cacheAndS3ErrorFiles) && swalUploadError({ cacheAndS3ErrorFiles });
  return uploadData;
};

export const handleLoadedFiles = (options) => {
  // use this method when you need to call PUT on an added file's uploadLink
  // this will PUT the file into S3
  const {
    includeFileErrors, // optional - if need individual file error data
    axiosRequest, // required
    isPublicRequest, // Optional - if token is NOT required to make api calls
    encodedFiles, // required - formatted files added by user with encoded values
    uploadFiles, // required - preloaded files/attached to resource with uploadLink
    hideFullPageAlertBar, // optional, don't show alert bar for api err
    updateState // optional, if using inner spinner
  } = options || {};
  return Promise.allSettled(uploadFiles
    .map((uploadFile) => {
      const encodedFile = !isEmpty(encodedFiles) && !isEmpty(uploadFile)
        ? encodedFiles
          .find(eFile => !isEmpty(eFile) && (uploadFile.originalFileName === eFile.name ||
            uploadFile.fileName === eFile.name ||
            uploadFile.fileName === eFile.fileName ||
            uploadFile.fileName === eFile.customFileName)) || {}
        : {};
      return putFileToS3({
        includeFileErrors,
        axiosRequest,
        isPublicRequest,
        encodedFile,
        uploadFile,
        hideFullPageAlertBar,
        updateState
      });
    }))
    .then((results) => {
      if (includeFileErrors) {
        const s3SuccessFiles = results.filter(r => (r.value && r.value.status === 'success')).map(r => r.value);
        const s3ErrorFiles = results.filter(r => (r.value && r.value.status === 'error')).map(r => ({ ...r.value, isS3Error: true }));
        return { s3SuccessFiles, s3ErrorFiles };
      }
      const errorOccurred = !isEmpty(results) &&
        results.some(result => result.value === 'errorOccurred');
      if (errorOccurred) {
        const errorOptions = { error: 'Failed to upload files', filesValid: false };
        return errorOptions;
      }
      const successOptions = { error: null, filesValid: true };
      return successOptions;
    });
};

const putFileToS3 = async options => new Promise(async (resolve) => {
  // Call S3 bucket upload link for each file
  const {
    includeFileErrors,
    uploadFile, // required, the file with the s3 uploadLink to be called
    encodedFile, // required, the file with the encoded value
    axiosRequest, // required
    isPublicRequest, // Optional - if token is NOT required to make api calls
    hideFullPageAlertBar, // optional, don't show alert bar for api err
    updateState // optional, if using inner spinner
  } = options || {};
  const { uploadLink, url: uploadUrl } = uploadFile || {};
  const { encoded, data, fileData } = encodedFile || {};
  const missingUploadLink = isEmpty(uploadLink) && isEmpty(uploadUrl);
  const missingEncodedValue = !(encoded instanceof Object) || encoded === null;
  if (missingUploadLink || missingEncodedValue || isEmpty(axiosRequest)) {
    const resolveErrOptions = includeFileErrors
      ? { status: 'error', errorMessage: 'missing required file data' }
      : 'errorOccurred';
    resolve(resolveErrOptions);
  } else {
    const useSpinner = updateState && hideFullPageAlertBar !== true;
    useSpinner && updateState({ spinnerLoading: true });
    const dataToCheck = !isEmpty(data) ? data : fileData || {};
    const requestUrl = uploadLink || uploadUrl;
    const fileType = !isEmpty(dataToCheck) && !isEmpty(dataToCheck.type) ? dataToCheck.type : 'application/octet-stream';
    const config = { headers: { 'Content-Type': fileType } };
    const axiosOptions = {
      ...((updateState || hideFullPageAlertBar) && { fullPageLoad: false }),
      url: requestUrl,
      method: 'put',
      config,
      ...(isPublicRequest && { tokenRequired: false, errorOptions: { alert40x: true } })
    };
    const apiRes = await retryAxiosRequest(
      // The request should be passed as an anonymous function (should NOT be invoked)
      () => axiosRequest(axiosOptions, encoded)
    );
    useSpinner && updateState({ ...apiRes.state });
    const { errorDetails } = apiRes || {};
    const fileName = (!isEmpty(uploadFile) && (uploadFile.fileName || uploadFile.name)) ||
      (!isEmpty(encodedFile) && (encodedFile.fileName || encodedFile.name));
    if (errorDetails instanceof Error) {
      const { alertBarMessage } = sharedGetInnerAlertBarState({ type: 'warning', data: errorDetails, axiosRequest });
      const resolveErrOptions = includeFileErrors
        ? {
          status: 'error',
          fileName,
          errorMessage: alertBarMessage,
          errorDetails
        }
        : 'errorOccurred';
      resolve(resolveErrOptions);
    } else {
      const resolveSuccessOptions = includeFileErrors
        ? { status: 'success', fileName }
        : 'success';
      resolve(resolveSuccessOptions);
    }
  }
});

// Checks if all files are valid for upload (eg, file size, dupe file names)
export const getValidatedFiles = (fileArray, options) => {
  const { existingFileNames, allFiles } = options || {};
  const filesToAdd = (fileArray instanceof Array) ? fileArray : [];
  const startingAcc = { filesWithErrors: {}, validFiles: [] };
  if (!isEmpty(filesToAdd)) {
    const validatedFiles = filesToAdd.reduce((acc, file) => {
      const { name = '', size = 0 } = file || {};
      const isDupe = (allFiles && allFiles[name] !== undefined) || (!isEmpty(existingFileNames) &&
    existingFileNames.find(existingFileName => name === existingFileName));
      const isTooLarge = !isEmpty(size) && size > 4900000000;
      const isTooSmall = !isEmpty(size) && size < 3; // Files under 3 bytes not allowed
      const isInvalid = isDupe || isTooLarge || isTooSmall;
      return isInvalid
        ? {
          ...acc,
          filesWithErrors: {
            ...acc.filesWithErrors,
            ...(isDupe && {
              dupeFiles: [...new Set([
                ...(!isEmpty(acc.filesWithErrors.dupeFiles) ? acc.filesWithErrors.dupeFiles : []),
                name
              ])]
            }),
            ...(isTooLarge && {
              filesTooLarge: (acc.filesWithErrors.filesTooLarge || []).concat(name)
            }),
            ...(isTooSmall && {
              filesTooSmall: (acc.filesWithErrors.filesTooSmall || []).concat(name)
            })
          }
        }
        : { ...acc, validFiles: (acc.validFiles || []).concat(file) };
    }, startingAcc);
    const { filesWithErrors } = validatedFiles || {};
    let alertBarMessage = '';
    let swalMessage = '';
    if (!isEmpty(filesWithErrors)) {
      const { dupeFiles, filesTooLarge, filesTooSmall } = filesWithErrors || {};
      const dupeFileNames = !isEmpty(dupeFiles) ? dupeFiles.join(', ') : '';
      const dupeFilesListMessage = !isEmpty(dupeFileNames) ? ` (these files already exist: ${dupeFileNames})` : '';
      const largeFileNames = !isEmpty(filesTooLarge) ? filesTooLarge.join(', ') : '';
      const filesTooLargeMessage = !isEmpty(largeFileNames) ? ` (these files are too large: ${largeFileNames})` : '';
      const smallFileNames = !isEmpty(filesTooSmall) ? filesTooSmall.join(', ') : '';
      const filesTooSmallMessage = !isEmpty(smallFileNames) ? ` (these files appear to be empty: ${smallFileNames})` : '';
      const messages = [
        ...(!isEmpty(dupeFileNames) ? [`no duplicate filenames allowed${dupeFilesListMessage}`] : []),
        ...(!isEmpty(filesTooLarge) ? [`files cannot be over 4.9GB in size${filesTooLargeMessage}`] : []),
        ...(!isEmpty(filesTooSmall) ? [`files cannot be empty${filesTooSmallMessage}`] : [])
      ].join('; ');
      alertBarMessage = `Sorry, ${!isEmpty(messages) ? messages : 'there was an unexpected error adding these files'}.`;
      // Swal messages
      const dupeFilesMessage = !isEmpty(dupeFiles) ? `\nNo duplicate filenames allowed. These files already exist:${dupeFiles.map(f => (`\n${f}`))}\n` : '';
      const largeFilesMessage = !isEmpty(filesTooLarge) ? `\nFiles cannot be over 4.9GB in size. These files are too large:${filesTooLarge.map(f => (`\n${f}`))}\n` : '';
      const smallFilesMessage = !isEmpty(filesTooSmall) ? `\nFiles cannot be empty. These files appear to be empty:${filesTooSmall.map(f => (`\n${f}`))}\n` : '';
      swalMessage = `${dupeFilesMessage}${largeFilesMessage}${smallFilesMessage}\nPlease correct these errors and try again.`;
    }
    return { ...validatedFiles, swalMessage, alertBarMessage };
  }
  const defaultError = 'Sorry, there are no files to add.';
  return { ...startingAcc, swalMessage: defaultError, alertBarMessage: defaultError };
};

export const handleCacheUploads = async (filesToUpload, options) => {
  const {
    axiosRequest, // Required
    isPublicRequest, // Optional - if token is NOT required to make api calls
    cacheUploadEndpoint, // Required
    useSpinner = false, // Optional - if `true`, pass updateState
    updateState // Optional - pass if using spinnerLoading
  } = options || {};
  const errorArray = [
    ...(isEmpty(filesToUpload) ? ['missing files to upload'] : []),
    ...(isEmpty(cacheUploadEndpoint) ? ['missing cache upload endpoint'] : [])
  ];
  if (isEmpty(errorArray)) {
    const requests = filesToUpload.map(fileForCache => () => getCacheUploadLink(fileForCache, {
      axiosRequest,
      isPublicRequest,
      cacheUploadEndpoint
    }));
    useSpinner && updateState && updateState({ spinnerLoading: true });
    return Promise.allSettled(requests.map(req => req()))
      .then((cacheResults) => {
        useSpinner && updateState && updateState({ spinnerLoading: false });
        const filesSuccessResults = cacheResults.filter(r => (r.value && r.value.status === 'success')).map(r => r.value);
        const filesErrorResults = cacheResults.filter(r => (r.value && r.value.status === 'error')).map(r => ({ ...r.value, isCacheError: true }));
        const errorFileNames = !isEmpty(filesErrorResults) ? filesErrorResults.map(r => r.fileName).join(', ') : '';
        return {
          allFilesCached: true,
          results: { filesErrorResults, errorFileNames, filesSuccessResults },
          errorMessage: ''
        };
      });
  }
  return { allFilesCached: false, results: {}, errorMessage: `Sorry, unable to upload files (${errorArray.join(', ')})` };
};

export const retryAxiosRequest = async (axiosRequest, options) => {
  const {
    maxRetryCount, // Optional - default is 3
    currentRetryCount,
    currentApiRes
  } = options || {};
  const currentCount = currentRetryCount ?? 1;
  const maxCount = maxRetryCount ?? 3;
  const allowRetry = currentCount <= maxCount;
  if (allowRetry) {
    /**
     * `axiosRequest` should already include all necessary options
     * so it just needs to be invoked here.
     */
    const nextApiRes = await axiosRequest();
    const isApiError = nextApiRes?.errorDetails instanceof Error;
    if (isApiError) {
      const errorStatus = getErrorStatusCode(nextApiRes?.errorDetails);
      const isNetworkError = errorStatus === 'ERR_NETWORK';
      if (isNetworkError) {
        return retryAxiosRequest(axiosRequest, {
          ...options,
          currentRetryCount: currentCount + 1,
          currentApiRes: nextApiRes
        });
      }
    }
    return nextApiRes;
  }
  return currentApiRes;
};

const getCacheUploadLink = (fileForCache, options) => new Promise(async (resolve) => {
  const {
    axiosRequest,
    cacheUploadEndpoint,
    isPublicRequest // Optional - if token is NOT required to make api calls
  } = options || {};
  const axiosOptions = {
    fullPageLoad: false,
    method: 'get',
    ...(isPublicRequest && { tokenRequired: false, errorOptions: { alert40x: true } }),
    // Safari caches simultaneous GET requests (w/ same headers)
    // so we need to pass a unique uri param for each request
    // in order for safari to recognize them as unique requests
    url: `${cacheUploadEndpoint}?uuid=${uuidv4()}`
  };
  const apiRes = await retryAxiosRequest(
    // The request should be passed as an anonymous function (should NOT be invoked)
    () => axiosRequest(axiosOptions)
  );
  const { data: backendData, errorDetails } = apiRes || {};
  const formattedCacheData = !isEmpty(backendData)
    ? { fileForCache, url: backendData.url, s3Key: backendData.s3Key }
    : {};
  const fileName = fileForCache.name || fileForCache.fileName;
  if (!isEmpty(formattedCacheData)) {
    resolve({ status: 'success', fileName, ...formattedCacheData });
  } else {
    const cacheErrorDetails = errorDetails instanceof Error ? errorDetails : 'Missing cache upload data';
    const { alertBarMessage } = sharedGetInnerAlertBarState({ type: 'warning', data: cacheErrorDetails, axiosRequest });
    resolve({
      status: 'error',
      fileName,
      errorMessage: alertBarMessage,
      errorDetails: cacheErrorDetails
    });
  }
});

export const swalUploadError = (options) => {
  const {
    swalPreText, // Custom message to show before error details
    cacheAndS3ErrorFiles,
    filesToAttach, // Files that successfully loaded to cache/s3, possibly resource
    attachToResourceError,
    useAttachToResource
  } = options || {};
  const getFileNames = filesArr => (filesArr || []).map(f => f.fileName).join(', ');
  const cacheS3ErrorList = !isEmpty(cacheAndS3ErrorFiles)
    ? cacheAndS3ErrorFiles.map(result => `${result.fileName ?? result.name} (${result.errorMessage})`)
    : [];
  const cacheS3Error = !isEmpty(cacheS3ErrorList)
    ? `The following file(s) failed to upload:\n${cacheS3ErrorList.join('\n\n')}`
    : '';
  const alertMessages = [
    ...(!isEmpty(swalPreText)) ? [swalPreText] : [],
    ...(useAttachToResource && !isEmpty(filesToAttach)
      ? [
        ...(!isEmpty(attachToResourceError) ? [`The following file(s) failed to attach:\n\n${getFileNames(filesToAttach)}\n\n${attachToResourceError}`] : []),
        // Some files uploaded successfully, but some failed due to cache/s3 upload errors
        ...(isEmpty(attachToResourceError) && !isEmpty(cacheS3ErrorList) ? [`The following file(s) successfully uploaded: ${getFileNames(filesToAttach)}`] : [])
      ]
      : []
    ),
    ...(!isEmpty(cacheS3Error) ? [cacheS3Error] : [])
  ];
  swal({
    title: `Upload Error`,
    text: `${alertMessages.join('\n\n')}`,
    button: { text: 'OK' },
    className: 'swal-corvia-error',
    dangerMode: true,
    icon: 'error'
  });
};

export const attachCachedS3FilesToResource = async (filesToAttach, options) => {
  const {
    axiosRequest,
    attachToResourceEndpoint,
    cacheAndS3ErrorFiles, // Only pass if cache/s3 uploads occur right before attach to resource
    defaultTagsOnAdd,
    fileAttachToResourceTemplate,
    useParentErrorSwal, // If errors should be handled in parent component
    fileGroup,
    swalPreText,
    templateVersion,
    requestGuid,
    updateState,
    useSpinner
  } = options || {};
  const useInnerSpinner = useSpinner ?? !isEmpty(updateState);
  useInnerSpinner && updateState({ spinnerLoading: true });
  const filesRequestBody = !isEmpty(filesToAttach)
    ? transformData({
      data: { files: filesToAttach, ...(!isEmpty(defaultTagsOnAdd) && { defaultTagsOnAdd }) },
      toSchema: 'backend',
      template: fileAttachToResourceTemplate,
      version: !isEmpty(templateVersion) ? templateVersion : '3.0'
    })
    : {};
  const attachToResourceResults = await handleAttachFilesToResource({
    attachToResourceEndpoint,
    axiosRequest,
    fileGroup,
    hideFullPageAlertBar: true,
    requestBody: filesRequestBody,
    requestGuid
  });
  useInnerSpinner && updateState({ spinnerLoading: false });
  const {
    allFilesAttached,
    errorMessage: attachToResourceError,
    fileGroup: resultFileGroup
  } = attachToResourceResults || {};
  const hasUploadErrors = !isEmpty(attachToResourceError) || !isEmpty(cacheAndS3ErrorFiles);
  !useParentErrorSwal && hasUploadErrors && swalUploadError({
    swalPreText,
    cacheAndS3ErrorFiles,
    attachToResourceError,
    filesToAttach,
    useAttachToResource: true
  });
  return {
    allFilesAttached,
    ...(!isEmpty(resultFileGroup) && { fileGroup: resultFileGroup }),
    ...(useParentErrorSwal && { attachToResourceError }),
    hasUploadErrors
  };
};

export const handleAttachFilesToResource = async (options) => {
  /**
   * Use this to attach temporary cached files to a permanent location
   * (after /cacheUploadLink api calls complete)
   */
  const {
    fileGroup, // Optional, unique identifer used by parent component
    attachToResourceEndpoint, // Required
    axiosRequest, // Required
    requestBody, // Required - formatted cached files data
    requestGuid, // Required - this method only works for existing resources
    hideFullPageAlertBar // Optional - if `true`, use spinnerLoading in parent
  } = options || {};
  const errorArray = [
    ...(isEmpty(attachToResourceEndpoint) ? ['missing file resource endpoint'] : []),
    ...(isEmpty(requestGuid) ? ['missing file resource'] : []),
    ...(isEmpty(requestBody) ? ['no files to add'] : [])
  ];
  if (isEmpty(errorArray)) {
    const axiosOptions = {
      ...(hideFullPageAlertBar && { fullPageLoad: false }),
      method: 'put',
      url: `${attachToResourceEndpoint}`,
      requestGuid
    };
    const apiRes = await retryAxiosRequest(
      // The request should be passed as an anonymous function (should NOT be invoked)
      () => axiosRequest(axiosOptions, requestBody)
    );
    if (!isEmpty(apiRes) && apiRes.errorDetails instanceof Error) {
      const { status } = apiRes.state || {};
      const { files } = requestBody || {};
      const errorMessageMap = {
        404: `Sorry, ${files && files.length > 1 ? 'these files do not' : 'this file does not'} exist in the system. Please upload your file${files && files.length > 1 ? 's' : ''} again.`,
        409: 'Sorry, no duplicate file names allowed. Please try again with unique file names.'
      };
      const { alertBarMessage } = errorMessageMap[status]
        ? { alertBarMessage: errorMessageMap[status] }
        : sharedGetInnerAlertBarState({ type: 'warning', data: apiRes.errorDetails, axiosRequest });
      return { allFilesAttached: false, fileGroup, errorMessage: alertBarMessage };
    }
    return { allFilesAttached: true, fileGroup, errorMessage: '' };
  }
  return { allFilesAttached: false, fileGroup, errorMessage: `Sorry, unable to add files (${errorArray.join(', ')})` };
};

export const getRemainingTime = (start, end) => {
  const getDateInstance = (dateVal) => {
    const newDate = isEmpty(dateVal)
      ? getNow() // returns static date for dev/test envs, else returns current date
      : dateVal;
    return newDate instanceof Date ? newDate : new Date(newDate);
  };
  if (!isEmpty(start) || !isEmpty(end)) {
    const startTime = getDateInstance(start);
    const endDate = getDateInstance(end);
    const totalSeconds = (endDate.getTime() - startTime.getTime()) / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor(totalSeconds % 3600 / 60);
    const seconds = Math.floor(totalSeconds % 3600 % 60);
    return { hours, minutes, seconds };
  }
  return { hours: 0, minutes: 0, seconds: 0 };
};

export const isImage = (fileName) => {
  const acceptedImageTypes = ['.gif', '.jpg', '.jpeg', '.png', '.apng', '.avif', '.webp', '.bmp', '.ico', '.cur', '.tif', '.tiff'];
  return acceptedImageTypes.some(fileExtension => (fileName || '').toLowerCase().endsWith(fileExtension));
};

/**
 * This helper method will create a key-to-englishName map for DataTable headers
 * and reorder the data based on the column order from the BE
 *
 * @param {*} headers array the headers obj for a DataTable received from the Backend
 * @param {*} data array the data obj for a DataTable received from the BE
 * @returns { headers, data, hiddenColumns } headers is a key-name map and data is
 *  re-ordered based on headers
 */
export const buildDataTable = (headers, data) => {
  const keyOrder = (headers || []).sort((a, b) => a?.order - b?.order);
  const keys = (keyOrder || []).map(item => item?.key);
  const columnNames = (keyOrder || []).map(item => item?.englishName);

  const headerKeyMap = {};
  keys.forEach((key, index) => {
    headerKeyMap[key] = columnNames[index];
  });
  const hiddenColumns = [];
  const entryKeys = Object.keys(data?.[0] ? data[0] : []);
  entryKeys.forEach((key) => {
    if (keys.indexOf(key) < 0) {
      hiddenColumns.push(key);
    }
  });
  return { header: { headerKeyMap, columnOrder: keys }, data, hiddenColumns };
};

export const filterColumnsFromTableData = (options) => {
  // use me to filter out table data given a set of columns you wish to include and exclude
  const {
    includedColumns = [], // array of key names as strings
    data = [] // array of objects with object name referring to above keys
  } = options;
  const hiddenColumns = [];
  const dataKeys = Object.keys(data[0]) || [];
  dataKeys.forEach((aDataKey) => {
    if (!includedColumns.includes(aDataKey)) {
      hiddenColumns.push(aDataKey);
    }
  });
  return hiddenColumns; // return only those keys which are in data but not in includedColumns
};

export const stringSplit = (input, separator = ',') => {
  const arr = input.split(separator).reduce((result, item) => {
    if (!isEmpty(getType(item) === 'string' ? item.trim() : item)) {
      result.push(item.trim());
    }
    return result;
  }, []);
  return arr;
};

export const arrJoin = (input, separator = ',') => input.filter(v => !isEmpty(getType(v) === 'string' ? v.trim() : v)).join(separator);

const generateHex = (r, g, b) => {
  let R = r.toString(16);
  let G = g.toString(16);
  let B = b.toString(16);

  while (R.length < 2) {
    R = `0${R}`;
  }
  while (G.length < 2) {
    G = `0${G}`;
  }
  while (B.length < 2) {
    B = `0${B}`;
  }

  return `#${R}${G}${B}`;
};

const mix = (start, end, percent) => start + (percent / 100) * (end - start);

export const colorBlender = (color1, color2, percent) => {
  const red1 = parseInt(`${color1[1]}${color1[2]}`, 16);
  const green1 = parseInt(`${color1[3]}${color1[4]}`, 16);
  const blue1 = parseInt(`${color1[5]}${color1[6]}`, 16);

  const red2 = parseInt(`${color2[1]}${color2[2]}`, 16);
  const green2 = parseInt(`${color2[3]}${color2[4]}`, 16);
  const blue2 = parseInt(`${color2[5]}${color2[6]}`, 16);

  const red = Math.round(mix(red1, red2, percent));
  const green = Math.round(mix(green1, green2, percent));
  const blue = Math.round(mix(blue1, blue2, percent));

  return generateHex(red, green, blue);
};

export const isSidebarOpen = () => !isEmpty(document.querySelector('#sidebarContent .sidebarContent'));

const isSiteModalOpen = () => (
  !isEmpty(document.querySelector('#siteModal.active .innerWrap')) ||
  !isEmpty(document.querySelector('#modal.active .innerWrap'))
);

export const isPublicUrl = typeof window !== 'undefined' && (`${window.location.pathname}`).startsWith('/public/');

export const publicGetFormattedRelationship = (params) => { // public web form only
  const { publicId } = params || {};
  // dev relationships
  const map = {
    [`a399ce37-8561-4638-b178-2f8cd1759715`]: { // FE-assigned uuid for public web form url
      crabConfigurationOptions: {},
      processName: 'repay',
      riskProfile: 'preferred',
      // bankName: '', // include if applicable
      feeId: '1e1c5913-160b-4fbc-afad-7320b360a50e' // FE-assigned uuid for BE
    },
    [`5aeb9320-42cc-44f5-a997-95aac5663dc6`]: {
      processName: 'repay',
      riskProfile: 'preferred',
      // bankName: '', // include if applicable
      feeId: 'eefb7ef5-b94a-4cc8-9e95-834424480557' // FE-assigned uuid for BE
    },
    [`511a8a87-7700-40a0-a63b-54395ff0376c`]: {
      processName: 'netevia',
      riskProfile: 'elevated',
      // bankName: '', // include if applicable
      feeId: '52fa54c0-de86-40e4-80cb-6861d2022e39' // FE-assigned uuid for BE
    },
    [`3311728e-c0b3-465e-8831-cd9b99c2fdf3`]: {
      processName: 'priority',
      riskProfile: 'elevated',
      bankName: 'axiom',
      feeId: '044f6dd0-7a34-45f2-a1b8-6188b293d6c1' // FE-assigned uuid for BE
    },
    [`cbc217c3-01bb-4afa-b510-c419ba7a4578`]: {
      processName: 'priority',
      riskProfile: 'elevated',
      bankName: 'wells_fargo',
      feeId: 'b902ff61-99e7-433e-a635-37df839bc68f' // FE-assigned uuid for BE
    },
    // stage relationships
    [`b5b49f57-c4f2-4fb6-85e0-8b22bb92fc27`]: { // FE-assigned uuid for public web form url
      processName: 'repay',
      riskProfile: 'preferred',
      // bankName: '', // include if applicable
      feeId: 'b280484e-431a-4cd5-8c1d-ab670f0a4ad9' // FE-assigned uuid for BE
    },
    [`745462d3-7fa2-4630-8818-bec572a72838`]: {
      processName: 'netevia',
      riskProfile: 'elevated',
      // bankName: '', // include if applicable
      feeId: 'a129f380-5047-47c3-b47d-0d32b3845e09' // FE-assigned uuid for BE
    },
    [`cf1c227c-3b5a-4503-8f62-c9e1b1a16fe5`]: {
      processName: 'priority',
      riskProfile: 'elevated',
      bankName: 'axiom',
      feeId: '3a737ed4-94e2-4a23-8a28-ca4e16332343' // FE-assigned uuid for BE
    },
    [`1b6c6346-ed09-4e43-9be3-60bae64740ab`]: {
      processName: 'priority',
      riskProfile: 'elevated',
      bankName: 'wells_fargo',
      feeId: '242a8fdc-1ea3-40e9-af34-dc0617894784' // FE-assigned uuid for BE
    }
  };
  return !isEmpty(publicId) ? map[publicId] : null;
};

export const getFormattedRelationship = (relationship) => {
  const appRelationshipName = relationship?.relationshipName || relationship?.title;
  const appRiskProfile = ignoreCase(relationship?.riskProfile || '');
  const appRelationshipId = relationship?.relationshipId || relationship?.value;
  const appProcessName = ignoreCase(relationship?.processorName || relationship?.processName || '');
  const appBankName = ignoreCase(relationship?.bankName || '');
  const appPaymentGateway = !isEmpty(relationship?.paymentGateway)
    ? ignoreCase(relationship?.paymentGateway || '')
    : null;
  const childPartnerId = relationship?.childPartnerId || '';
  const formattedRelationship = {
    crabConfigurationOptions: relationship?.crabConfigurationOptions,
    bankName: appBankName,
    paymentGateway: appPaymentGateway,
    processorName: appProcessName,
    processName: appProcessName,
    relationshipCode: relationship?.relationshipCode,
    riskProfile: appRiskProfile,
    relationshipName: appRelationshipName,
    relationshipId: appRelationshipId,
    title: appRelationshipName,
    value: appRelationshipId,
    childPartnerId
  };
  return formattedRelationship;
};

export const handleCloseSwal = () => (swal &&
  // validate swal exists before trying to close
  swal.length && swal.close());

export const handleVersionHeaderError = () => {
  // 426 error, user's version header (in prod only) different than the latest prod push
  swal({
    title: 'Refresh Required',
    text: errorCodes[426],
    className: 'swal-corvia-default',
    buttons: {
      ok: 'Refresh Page'
    },
    icon: 'warning',
    closeOnClickOutside: false,
    closeOnEsc: false
  }).then(() => {
    window.location.reload(true);
  });
};

export const dedupeList = (list, key) => {
  const deduped = (list || []).reduce((acc, item) => {
    const exists = !isEmpty(
      (acc || []).find(accItem => (
        (key && !isEmpty(item[key]) && isEqual(item[key], accItem[key])) ||
        (!isEmpty(item.value) && isEqual(item.value, accItem.value)) ||
        (!isEmpty(item.guid) && isEqual(item.guid, accItem.guid))
      ))
    );
    return exists ? acc : acc.concat(item);
  }, []);
  return deduped;
};

export const dataExists = (data) => {
  /**
   * Use for checking if nested data and/or data with different types
   * has SOME values that are not empty. Can be used for checking
   * if BE data exists for knowing if a checkbox should be checked on load
   */
  if (isEmpty(data) && !isBool(data)) { return false; }
  if (getType(data) === 'object') {
    const objectValues = Object.values(data);
    return objectValues.some(dataExists);
  }
  if (getType(data) === 'array') {
    return data.some(dataExists);
  }
  return !isEmpty(data) || isBool(data) || false;
};

export const setClickTrail = async (e, options) => {
  const { getFromLocalDB, addToLocalDB } = options || {};
  const clickedArray = await getFromLocalDB('clickTrail', options); // include `options` for axiosRequest prop
  const { target } = e;
  const newArray = !isEmpty(clickedArray) ? [...clickedArray] : [];
  if (target) {
    const attributes = {
      ...(!isEmpty(target.type || target.tagName) && { nodeType: target.type || target.tagName }),
      ...(!isEmpty(target.getAttribute('class')) && { class: target.getAttribute('class') }),
      ...(!isEmpty(target.getAttribute('id')) ? {
        id: target.getAttribute('id')
      } : {
        closestId: target.closest('[id]')?.id
      }),
      ...(!isEmpty(target.textContent) && { textContent: target.textContent }),
      activePage: typeof window !== 'undefined' ? window.location.href.split('/').pop() : '/windowLocationUndefined'
    };
    newArray.push(attributes);
    if (newArray.length > 5) { newArray.shift(); }
    await addToLocalDB('clickTrail', newArray, options); // include `options` for axiosRequest prop
  }
};

export const replaceControlCharacters = (string, options) => {
  const {
    replaceWith = ' ',
    keepEscapes = false, // keep returns and tabs
    noSequentialSpaces = false // remove sequnential spaces, only allow one space
  } = options || {};
  // eslint-disable-next-line no-control-regex
  const noControlCharacters = /(\r\n|\n|\r)|[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/gm; // remove control characters from all inputs
  // eslint-disable-next-line no-control-regex
  const keepTabsAndNewlines = /[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/gm; // keep newline/tabs
  const cleaned = keepEscapes
    ? `${string}`.replace(keepTabsAndNewlines, replaceWith)
    : `${string}`.replace(noControlCharacters, replaceWith);
  return noSequentialSpaces ? cleaned.replace(/\s+/g, ' ') : cleaned;
};

export const getDataType = (name) => {
  switch (name) {
    case 'Close to Closure':
      return 'unhealthyMerchants';
    case 'Healthy MIDs':
      return 'healthyMerchants';
    case 'In Danger':
      return 'inDangerMerchants';
    case 'On A Program':
      return 'onProgramMerchants';
    default:
      return 'healthyMerchants';
  }
};

export const getGridColCount = (parent) => {
  let leftTracker = 0;
  let length = 0;
  if (parent) {
    const children = parent.childNodes;
    for (let i = 0; i < children.length; i += 1) {
      const { left } = children[i].getBoundingClientRect();
      if (left > leftTracker) {
        leftTracker = left;
        length = i + 1;
      } else {
        break;
      }
    }
  }
  return length === 0 ? 1 : length;
};
