import React from 'react';
import { PropTypes } from 'prop-types';
import {
  transformData,
  isEmpty,
  getFormattedRelationship,
  sharedGetInnerAlertBarState,
  snakeToTitle,
  isEqual
} from '../../_helpers';
import { CustomApiErr, Button, Spinner } from '../../../index';
import {
  crabFeesOnlyFields
} from '../../_crabFields';
import NeteviaTab from '../../boarding/components/NeteviaTab';
import RepayTab from '../../boarding/components/RepayTab';
import PriorityTab from '../../boarding/components/PriorityTab';
import { sharedBoardingTemplate } from '../../data/sharedBoarding/templates/sharedBoardingTemplate';
import { merchantFeesTemplate } from '../../data/sharedBoarding/templates/merchantFeesTemplate';
import { useDefaultProcessor } from '../../data/sharedBoarding/templates/crabV1ApplicationTemplate';
import { isPriorityFirstDataBank, isPriorityTsysBank } from '../../_templateHelpers';

export class MerchantFees extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { userType } = props;
    this.isPortalUser = ['merchant', 'partner'].includes(userType);
    this.state = {
      spinnerLoading: false,
      err: false,
      formValid: false,
      formInProgress: false,
      feesLoaded: false,
      feesTabFormFields: {},
      isDefaultProcessor: false,
      relationship: {},
      feesData: {},
      originalBackendJson: {}
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.initialize();
  }

  componentDidUpdate (prevProps) {
    const {
      guid,
      userType,
      relationshipList,
      relationshipId,
      portalData
    } = this.props;
    if ((this.isPortalUser &&
      (!isEqual(prevProps.guid, guid) ||
      !isEqual(prevProps.portalData?.timestamp, portalData?.timestamp))) ||
    (userType === 'employee' && (!isEqual(prevProps.guid, guid) || !isEqual(prevProps.relationshipId, relationshipId))) ||
    (userType === 'employee' && isEmpty(prevProps.relationshipList) && !isEmpty(relationshipList))) {
      this.initialize();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  initialize = () => {
    const { userType } = this.props;
    if (this.isPortalUser) {
      this.updateState({ feesLoaded: false }, this.setPortalFees);
    } else if (userType === 'employee') {
      this.checkProcessor();
    }
  }

  checkProcessor = () => { // employee only
    const { relationshipId, relationshipList } = this.props;
    if (!isEmpty(relationshipList) && !isEmpty(relationshipId)) {
      const relationshipMatch = relationshipList.find(r => r.value === relationshipId);
      if (!isEmpty(relationshipMatch)) {
        const transformOptions = this.getTransformOverrides(relationshipMatch);
        this.setIsDefaultProcessor(relationshipMatch || {}, transformOptions);
      } else { // If no match on relationshipList in store, call GET relationship endpoint
        this.setRelationship();
      }
    }
  }

  setIsDefaultProcessor = (relationship, transformOptions) => {
    const formattedRelationship = getFormattedRelationship(relationship || {});
    const isDefaultProcessor = useDefaultProcessor(formattedRelationship || {}, {
      defaultProcessorOverride: true
    });
    this.updateState({
      isDefaultProcessor,
      relationship: formattedRelationship
    }, !this.isPortalUser ? () => this.getFees(transformOptions) : null);
  }

  setRelationship = async () => { // employee only
    const { relationshipId, getRelationshipEndpoint, axiosRequest } = this.props;
    if (!isEmpty(getRelationshipEndpoint) && !isEmpty(relationshipId)) {
      this.updateState({ spinnerLoading: true, feesLoaded: false });
      const apiRes = await axiosRequest({
        method: 'get',
        fullPageLoad: false,
        url: `${getRelationshipEndpoint}`,
        requestGuid: { relationshipId }
      });
      this.updateState({ spinnerLoading: false });
      const { relationship } = apiRes?.errorDetails instanceof Error ? {} : apiRes?.data || {};
      const transformOptions = this.getTransformOverrides(relationship || {});
      this.setIsDefaultProcessor(relationship || {}, transformOptions);
    }
  }

  getTransformOverrides = (relationship) => {
    const { userType } = this.props;
    if (!isEmpty(relationship)) {
      const relationshipDetails = getFormattedRelationship(relationship || {});
      const { processName, riskProfile } = relationshipDetails || {};
      !this.isPortalUser && this.updateState({
        relationship: relationshipDetails
      });
      const transformOptions = {
        appType: 'lowRisk',
        isEmployee: userType === 'employee',
        relationship: relationshipDetails,
        riskLevel: riskProfile,
        processName
      };
      return transformOptions;
    }
    return {};
  }

  getFormFields = (options) => {
    const { bankName, riskProfile, processName } = options || {};
    const formFields = crabFeesOnlyFields({ bankName, riskProfile, processName });
    return formFields;
  }

  getFees = async (options) => { // employee only
    const { axiosRequest, guid, endpoint } = this.props;
    const { isDefaultProcessor } = this.state;
    if (!isDefaultProcessor && !isEmpty(guid)) {
      this.updateState({ spinnerLoading: true, feesLoaded: false });
      const apiRes = await axiosRequest({
        method: 'get',
        fullPageLoad: false,
        url: endpoint,
        requestGuid: { merchantGuid: guid }
      });
      this.updateState({ ...apiRes?.state });
      if (apiRes?.errorDetails instanceof Error) {
        this.updateState({ feesLoaded: false, feesData: {}, originalBackendJson: {} });
      } else {
        const formattedRelationship = getFormattedRelationship(options?.relationship || {});
        const { bankName, riskProfile, processName } = formattedRelationship || {};
        const isNetevia = processName === 'netevia';
        const isRepay = processName === 'repay';
        const isPriorityTsys = isPriorityTsysBank(formattedRelationship);
        const isPriorityFirstData = isPriorityFirstDataBank(formattedRelationship);
        const transformOptions = {
          appType: 'lowRisk',
          ...options
        };
        const formattedRepayFees = isRepay
          ? this.getFormattedRepayFees(apiRes, transformOptions)
          : {};
        const formattedNeteviaFees = isNetevia
          ? this.getFormattedNeteviaFees(apiRes, transformOptions)
          : {};
        const formattedPriorityFees = (isPriorityTsys || isPriorityFirstData)
          ? this.getFormattedPriorityFees(apiRes, transformOptions)
          : {};
        const formFields = this.getFormFields({
          bankName,
          riskProfile,
          processName,
          transformOptions
        });
        this.updateState({
          feesLoaded: true,
          feesTabFormFields: formFields,
          originalBackendJson: { mpa: apiRes?.data || {} },
          feesData: {
            ...(isRepay && { ...formattedRepayFees }),
            ...(isNetevia && { ...formattedNeteviaFees }),
            ...((isPriorityTsys || isPriorityFirstData) && { ...formattedPriorityFees })
          }
        });
      }
    }
  }

  setPortalFees = () => { // partner/merchant portal
    const { userType, portalData } = this.props;
    const { relationship, apiRes } = portalData || {};
    const { bankName, processName, riskProfile } = getFormattedRelationship(relationship || {});
    if (apiRes?.errorDetails instanceof Error) { // error on GET merchant detail api
      this.updateState({ ...apiRes?.state, feesLoaded: false, feesData: {} });
    } else {
      const feesResponse = apiRes?.data?.merchant?.fees || {};
      const formattedRelationship = {
        bankName,
        riskProfile,
        processName,
        // merchants don't have relationships so this overrides
        // the passed in relationship data with the logic of
        // if a fieldObject has values, it is that kind of relationship
        ...(userType === 'merchant' && {
          ...(!isEmpty(feesResponse.repayFields) && { processName: 'repay' }),
          ...(!isEmpty(feesResponse.neteviaFields) && { processName: 'netevia' }),
          ...(!isEmpty(feesResponse.priorityFields) && {
            processName: 'priority',
            // note, the value 'axiom' is a lie, it is only used because it is one of
            // the supported banks at TSYS. Do NOT use the bank to make decisions
            // for anything other than formatting, it should never be displayed to the user
            ...(!isEmpty(feesResponse.priorityFields.tsysRatesAndFees) && { bankName: 'axiom' }),
            ...(!isEmpty(feesResponse.priorityFields.firstDataRatesAndFees) && { bankName: 'wells_fargo' })
          })
        })
      };
      const isRepay = formattedRelationship.processName === 'repay';
      const isNetevia = formattedRelationship.processName === 'netevia';
      const isPriorityTsys = isPriorityTsysBank(formattedRelationship);
      const isPriorityFirstData = isPriorityFirstDataBank(formattedRelationship);

      const formFields = this.getFormFields({
        bankName: formattedRelationship.bankName,
        riskProfile: formattedRelationship.riskProfile,
        processName: formattedRelationship.processName
      });
      const transformOptions = {
        ...this.getTransformOverrides(formattedRelationship),
        relationship: formattedRelationship
      };
      const formattedRepayFees = isRepay
        ? this.getFormattedRepayFees({ data: feesResponse.repayFields }, transformOptions)
        : {};
      const formattedNeteviaFees = isNetevia
        ? this.getFormattedNeteviaFees({ data: feesResponse.neteviaFields }, transformOptions)
        : {};
      const formattedPriorityFees = (isPriorityTsys || isPriorityFirstData)
        ? this.getFormattedPriorityFees({ data: feesResponse }, transformOptions)
        : {};
      this.updateState({
        feesLoaded: true,
        feesData: {
          ...(!isEmpty(formattedRepayFees) && { ...formattedRepayFees }),
          ...(!isEmpty(formattedNeteviaFees) && { ...formattedNeteviaFees }),
          ...((isPriorityTsys || isPriorityFirstData) && { ...formattedPriorityFees })
        },
        feesTabFormFields: formFields,
        relationship: formattedRelationship
      });
    }
  }

  getFormattedRepayFees = (apiRes, options) => {
    const { relationship } = options || {};
    const { riskProfile } = relationship || {};
    const { disabledFormFields } = this.props;
    const feeSections = transformData({
      data: {
        backendData: apiRes?.data || {},
        options: {
          ...options,
          riskLevel: riskProfile,
          processName: 'repay',
          disableFormFields: disabledFormFields
        }
      },
      template: merchantFeesTemplate,
      toSchema: 'frontendRepayFees',
      version: '1.0'
    });
    return feeSections;
  }

  getFormattedNeteviaFees = (apiRes, options) => {
    const { relationship } = options || {};
    const { riskProfile } = relationship || {};
    const { disabledFormFields } = this.props;
    const feeSections = transformData({
      data: {
        backendData: apiRes?.data || {},
        options: {
          ...options,
          riskLevel: riskProfile,
          processName: 'netevia',
          disableFormFields: disabledFormFields
        }
      },
      template: merchantFeesTemplate,
      toSchema: 'frontendNeteviaFees',
      version: '1.0'
    });
    return feeSections;
  }

  getFormattedPriorityFees = (apiRes, options) => {
    const { relationship } = options || {};
    const { bankName, riskProfile } = relationship || {};
    const { disabledFormFields } = this.props;
    const feeSections = transformData({
      data: {
        backendData: apiRes?.data || {},
        options: {
          ...options,
          riskLevel: riskProfile,
          processName: 'priority',
          bankName,
          disableFormFields: disabledFormFields
        }
      },
      template: merchantFeesTemplate,
      toSchema: 'frontendPriorityFees',
      version: '1.0'
    });
    return feeSections;
  }

  handleFormChange = (options) => {
    const { tabData, tabValid, tabInProgress } = options || {};
    const { formChangeCallback } = this.props;
    this.updateState(prevState => ({
      formInProgress: tabInProgress,
      formValid: tabValid,
      feesData: { ...prevState.feesData, ...tabData }
    }), formChangeCallback ? () => formChangeCallback({ ...options, tabId: 'feesTab' }) : null);
  }

  handleSave = async () => {
    const {
      axiosRequest,
      endpoint,
      guid,
      alertBar
    } = this.props;
    const { relationship, originalBackendJson, feesData } = this.state;
    if (!isEmpty(guid)) {
      alertBar('closed', '');
      const requestBody = transformData({
        data: {
          updatedMpa: { merchantFees: feesData },
          appType: 'lowRisk',
          originalBackendJson,
          relationship
        },
        toSchema: 'backendSaveFees',
        template: sharedBoardingTemplate,
        version: '1.0'
      });
      if (isEmpty(requestBody)) {
        const { processName, bankName } = getFormattedRelationship(relationship);
        alertBar('warning', `Sorry, an error occurred updating fees (Unsupported processor/bank: ${processName}/${bankName}).`);
        return;
      }
      this.updateState({ spinnerLoading: true });
      const apiRes = await axiosRequest({
        method: 'post',
        fullPageLoad: false,
        url: endpoint,
        requestGuid: { merchantGuid: guid }
      }, requestBody);
      this.updateState({ // don't use apiRes?.state here, it will override GET api call state
        spinnerLoading: false
      });
      if (apiRes?.errorDetails instanceof Error) {
        const alertBarState = sharedGetInnerAlertBarState({ axiosRequest, type: 'warning', data: apiRes?.errorDetails });
        alertBar('warning', alertBarState.alertBarMessage);
      } else {
        alertBar('success', 'Success! Fees have been updated.');
        this.updateState({
          originalBackendJson: { mpa: requestBody },
          formInProgress: false
        });
      }
    }
  }

  render () {
    const { disabledFormFields, hideSave, userType } = this.props;
    const {
      feesLoaded,
      feesData,
      isDefaultProcessor,
      relationship,
      feesTabFormFields,
      spinnerLoading,
      status,
      err,
      formInProgress,
      formValid
    } = this.state;
    const { processName, bankName } = relationship || {};
    const isRepay = processName === 'repay';
    const isNetevia = processName === 'netevia';
    const isPriorityTsys = isPriorityTsysBank(relationship);
    const isPriorityFirstData = isPriorityFirstDataBank(relationship);
    const showDefaultProcessMessage = !err && isDefaultProcessor && userType === 'employee';
    const hasFeeWebsite = (isRepay && bankName === 'mvb') || processName === 'first_data';
    return (
      <div
        id="merchant-fees"
        style={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column'
        }}
      >
        <Spinner loading={spinnerLoading} />
        {!isDefaultProcessor && feesLoaded && !isEmpty(feesData)
          ? (
            <>
              {isRepay && (
                <RepayTab
                  userType={userType}
                  data={feesData}
                  fields={feesTabFormFields}
                  disableFormFields={disabledFormFields}
                  appType="lowRisk"
                  callback={this.handleFormChange}
                  relationship={relationship}
                  onlyUseFeeData
                />
              )}
              {isNetevia && (
                <NeteviaTab
                  userType={userType}
                  data={feesData}
                  fields={feesTabFormFields}
                  disableFormFields={disabledFormFields}
                  appType="lowRisk"
                  callback={this.handleFormChange}
                  relationship={relationship}
                  onlyUseFeeData
                  useNewFeeFormat
                />
              )}
              {(isPriorityTsys || isPriorityFirstData) && (
                <PriorityTab
                  userType={userType}
                  data={feesData}
                  fields={feesTabFormFields}
                  disableFormFields={disabledFormFields}
                  appType="lowRisk"
                  callback={this.handleFormChange}
                  relationship={relationship}
                  onlyUseFeeData
                  useNewFeeFormat
                />
              )}
              {!disabledFormFields && !hideSave && (
                <Button
                  id="save-button"
                  onClick={this.handleSave}
                  disabled={!formInProgress || !formValid}
                  style={{ margin: '0.5em 0' }}
                >
                  Save
                </Button>
              )}
            </>
          )
          : (
            <CustomApiErr
              spinnerLoading={spinnerLoading}
              status={status}
              customErr={err}
              {...(showDefaultProcessMessage && {
                customMessage: `Fees for this merchant can be managed in ${processName ? snakeToTitle(processName) : 'the merchant processor'}'s system`
              })}
            />
          )}
        {!err && userType === 'employee' && !disabledFormFields && hasFeeWebsite && (
          <div style={{ fontSize: '1.3rem', textAlign: 'center', wordBreak: 'break-word' }}>
            {processName === 'first_data' && (
              <>
                First Data (Fiserv): AccessOne:&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://www.youraccessone.com/64_rpt_Home.aspx">https://www.youraccessone.com/64_rpt_Home.aspx</a>
              </>
            )}
            {processName === 'repay' && bankName === 'mvb' && (
              <>
                Merchant Entry System (MES):&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://legacy.trisourcesolutions.com/mes/Login/Login.asp">https://legacy.trisourcesolutions.com/mes/Login/Login.asp</a>
              &nbsp;(Requires IE Tab chrome extension)
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

MerchantFees.propTypes = {
  endpoint: PropTypes.string,
  getRelationshipEndpoint: PropTypes.string,
  guid: PropTypes.string,
  relationshipId: PropTypes.string,
  relationshipList: PropTypes.oneOfType([PropTypes.array]),
  alertBar: PropTypes.func,
  axiosRequest: PropTypes.func,
  formChangeCallback: PropTypes.func, // If parent comp handles form validation
  hideSave: PropTypes.bool, // Use this if parent comp handles the save api call
  disabledFormFields: PropTypes.bool,
  userType: PropTypes.string,
  portalData: PropTypes.shape({
    apiRes: PropTypes.oneOfType([PropTypes.object]),
    relationship: PropTypes.oneOfType([PropTypes.object]),
    timestamp: PropTypes.number
  })
};

MerchantFees.defaultProps = {
  endpoint: '',
  getRelationshipEndpoint: '', // Employee only
  guid: '',
  relationshipId: '', // Employee only
  relationshipList: [], // Employee only
  alertBar: () => {},
  axiosRequest: () => {},
  formChangeCallback: null,
  hideSave: false,
  disabledFormFields: false,
  userType: '',
  portalData: { // Partner/merchant portal only
    apiRes: null,
    relationship: {},
    timestamp: 0
  }
};

export default MerchantFees;
