import '@f1/shared/src/wdyr'; // <--- first import
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { LoadingPage, ScrollToTop, ErrorBoundary } from '@f1/shared';
import '@f1/shared/css/global.css';
import '@f1/shared/css/darkMode.css';
import Loader from '@f1/shared/src/Loader';
import { envIsDevOrLess } from '@f1/shared/src/_helpers';
import AchReport from '@f1/shared/src/pages/ACHReport';
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import SiteHeader from './components/SiteHeader';
import { SiteFooter } from './components/SiteFooter';
import App from './App';

import * as serviceWorker from './serviceWorker';
import store, { persistor } from './redux/store';
import {
  getLocalDbNestedMerchants,
  axiosRequest,
  crmGetAllPageData
} from './utils';
import { crmEndpoint } from './utilsEndpoints';

const CustomSettingsDesign = lazy(() => import('./pages/CustomSettingsDesign'));
const Home = lazy(() => import('./pages/Home'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Merchant = lazy(() => import('./pages/Merchant'));
const Relationship = lazy(() => import('./pages/Relationship'));
const Partner = lazy(() => import('./pages/Partner'));
const Account = lazy(() => import('./pages/Account'));
const SignIn = lazy(() => import('./pages/SignIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const NotFound = lazy(() => import('@f1/shared/src/pages/404'));
const Confirm = lazy(() => import('./pages/Confirm'));
const IT = lazy(() => import('./pages/IT'));
const Support = lazy(() => import('./pages/Support'));
const Tickets = lazy(() => import('./pages/Tickets'));
const Application = lazy(() => import('./pages/Application'));
const ApplicationConfiguration = lazy(() => import('./pages/ApplicationConfiguration'));
const Prevet = lazy(() => import('./pages/Prevet'));
const Match = lazy(() => import('./pages/Match'));
const Sticky = lazy(() => import('./pages/Sticky'));
const RiskLanding = lazy(() => import('./pages/RiskLanding'));
const RiskRules = lazy(() => import('./pages/RiskRules'));
const BinStatus = lazy(() => import('./pages/BinStatus'));
const ActionSuggested = lazy(() => import('./pages/ActionSuggested'));
const TrainingWrapper = lazy(() => import('./components/TrainingWrapper'));
const ResidualsPage = lazy(() => import('./pages/ResidualsPage'));
const ResidualsV2 = lazy(() => import('./pages/ResidualsV2'));
const ResidualsV3Mockup = lazy(() => import('./pages/residuals/v3Mockup/ResidualsV3Mockup'));
const NachaPage = lazy(() => import('./pages/Nacha'));
const MerchantProfitability = lazy(() => import('./pages/Profitability'));
const RepaySftp = lazy(() => import('./pages/RepaySftp'));
const Underwriting = lazy(() => import('./pages/Underwriting'));
const MerchantLedgerWrapper = lazy(() => import('./components/MerchantLedgerWrapper'));
const Modal = lazy(() => import('@f1/shared/src/Modal'));
const ProhibitedEntities = lazy(() => import('./pages/ProhibitedEntities'));
const BatchDetailsWrapper = lazy(() => import('./pages/BatchDetailsWrapper'));
const RepayTransactionHold = lazy(() => import('./pages/RepayTransactionHold'));
const ReserveDetailsWrapper = lazy(() => import('./pages/ReserveDetailsWrapper'));
const TransactionProfitability = lazy(() => import('./pages/TransactionProfitability'));
const ApplicationPendReport = lazy(() => import('./boarding/ApplicationPendReport'));

// set theme with: localStorage.setItem('corviaTheme', 'dark');
const activeTheme = localStorage.getItem('corviaTheme');
if (activeTheme === 'dark') {
  /* istanbul ignore next */
  document.querySelector('html').setAttribute('data-theme', 'corvia dark');
}

const Routing = (
  <Provider store={store}>
    <PersistGate loading={<LoadingPage />} persistor={persistor}>
      <BrowserRouter>
        <ErrorBoundary customOptions={{ axiosRequest, userEmail: localStorage.getItem('userEmail') }} store={store}>
          <ScrollToTop />
          <SiteHeader />
          <Suspense fallback={<LoadingPage />}>
            <App>
              <Routes>
                { /* PUBLIC */ }
                <Route path="*" element={<NotFound />} />
                <Route element={(<PublicRoute />)}>
                  <Route exact path="/signin" element={<SignIn />} />
                  <Route exact path="/signup" element={<SignUp />} />
                  <Route exact path="/confirm" element={<Confirm />} />
                  <Route exact path="/forgot" element={<ForgotPassword />} />
                  {envIsDevOrLess() && ( // only reference, do not show in prod
                    <Route path="/designs/custom-settings" element={<CustomSettingsDesign />} />
                  )}
                </Route>
                { /* PRIVATE */ }
                <Route element={(<PrivateRoute />)}>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/merchant" element={<Merchant />} />
                  <Route exact path="/merchant/txnsummary" element={<Merchant />} />
                  <Route exact path="/merchant/fees" element={<Merchant />} />
                  <Route exact path="/merchant/neteviaFees" element={<Merchant />} />
                  <Route exact path="/merchant/batch" element={<Merchant />} />
                  <Route exact path="/merchant/authorization" element={<Merchant />} />
                  <Route exact path="/merchant/sales" element={<Merchant />} />
                  <Route exact path="/merchant/refund" element={<Merchant />} />
                  <Route exact path="/merchant/chargebacks" element={<Merchant />} />
                  <Route exact path="/merchant/achFunding" element={<Merchant />} />
                  <Route exact path="/merchant/dispute" element={<Merchant />} />
                  <Route exact path="/merchant/ledger" element={<Merchant />} />
                  <Route exact path="/merchant/third-party-services" element={<Merchant />} />
                  <Route exact path="/merchant/reserves" element={<Merchant />} />
                  <Route exact path="/relationship" element={<Relationship />} />
                  <Route exact path="/partner" element={<Partner />} />
                  <Route exact path="/account" element={<Account />} />
                  <Route exact path="/it" element={<IT />} />
                  <Route exact path="/support" element={<Support />} />
                  <Route exact path="support/vantiv" element={<Support />} />
                  <Route exact path="/tickets" element={<Tickets />} />
                  <Route exact path="/application-v2" element={<Application />} />
                  <Route exact path="/application/configuration" element={<ApplicationConfiguration />} />
                  <Route exact path="/prevet" element={<Prevet />} />
                  <Route exact path="/application/match" element={<Match />} />
                  <Route exact path="/application/sticky" element={<Sticky />} />
                  <Route exact path="/application/report/pend" element={<ApplicationPendReport />} />
                  <Route exact path="/repay" element={<RepaySftp />} />
                  <Route exact path="/prohibitedEntities" element={<ProhibitedEntities />} />
                  <Route exact path="/risk" element={<RiskLanding />} />
                  <Route exact path="/risk/rules" element={<RiskRules />} />
                  <Route exact path="/risk/binStatus" element={<BinStatus />} />
                  <Route exact path="/risk/repayTransactionHold" element={<RepayTransactionHold />} />
                  <Route exact path="/reports/reserves" element={<ReserveDetailsWrapper />} />
                  <Route exact path="/reports/transactionProfitability" element={<TransactionProfitability />} />
                  <Route exact path="/binStatus" element={<BinStatus />} />
                  <Route exact path="/partner/actionSuggested" element={<ActionSuggested />} />
                  <Route exact path="/reports/batchDetails" element={<BatchDetailsWrapper />} />
                  <Route
                    path="/partner/achReport"
                    element={(
                      <AchReport
                        axiosRequest={axiosRequest}
                        location="crm"
                        store={store}
                        getLocalDbNestedMerchants={getLocalDbNestedMerchants}
                        achDetailsEndpoint={crmEndpoint.merchant.v2AchDetails}
                        achFundingEndpoint={crmEndpoint.merchant.achFunding}
                        getAllPageData={crmGetAllPageData}
                      />
                    )}
                  />
                  <Route exact path="/training" element={<TrainingWrapper location="training" />} />
                  <Route exact path="/training/lesson" element={<TrainingWrapper location="lesson" />} />
                  <Route exact path="/residualsv1" element={<ResidualsPage />} />
                  <Route exact path="/residualsv2" element={<ResidualsV2 />} />
                  <Route exact path="/residualsv3mockup" element={<ResidualsV3Mockup />} />
                  <Route exact path="/residualsv1/mid-mapping" element={<ResidualsPage />} />
                  <Route exact path="/nacha" element={<NachaPage />} />
                  <Route exact path="/profitability" element={<MerchantProfitability />} />
                  <Route exact path="/support/ach/upload" element={<Support />} />
                  <Route exact path="/repay-mid-list" element={<Underwriting />} />
                  {/* TODO SNEK-2049: remove env check */}
                  {envIsDevOrLess() && <Route exact path="/merchant/merchantLedger" element={<MerchantLedgerWrapper />} />}
                </Route>
              </Routes>
            </App>
            <Modal store={store} />
          </Suspense>
          <SiteFooter />
          <Loader store={store} />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(Routing, document.getElementById('root') || document.createElement('div'));

serviceWorker.unregister();
