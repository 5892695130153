import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { endpoint } from './_helpers';
import { shield } from '../images/_svgs';
import { apiCall } from './_api';
import { Spinner } from './Spinner';
import { sharedStubDataMap } from './data/stubDataMap';

export class Captcha extends React.Component {
  constructor (props) {
    super(props);
    this.captcha = null;
    this.setCaptchaRef = r => (this.captcha = r);
    this.mounted = false;
    this.state = {
      captchaValid: false,
      siteKey: '',
      loader: false
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.getSiteKey();
  }

  componentDidUpdate (prevProps, prevState) {
    const { captchaValid } = this.state;
    const { codeValid } = this.props;
    if ((prevState.captchaValid !== captchaValid && !captchaValid) ||
    (prevProps.codeValid !== codeValid && !codeValid)) {
      this.resetCaptcha();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  getSiteKey = async () => {
    const { handleApiError } = this.props;
    if (this.mounted) {
      await this.updateState({ loader: true });
      const apiUrl = `${endpoint.parameter.captchaSiteKey}`;
      const options = {
        url: apiUrl,
        method: 'get',
        tokenRequired: false,
        stubData: sharedStubDataMap[apiUrl]
      };
      const utils = {
        handleApiError
      };
      const apiRes = await apiCall(options, utils);
      if (apiRes?.data) {
        this.updateState({
          siteKey: apiRes.data.captchaSiteKey
        });
        // bypass captcha for testCafe & jest
        this.checkCaptchaBypass();
        setTimeout(() => {
          this.setId();
        }, 500);
      } else {
        this.updateState({ loader: false });
        handleApiError(apiRes?.errorDetails);
      }
    }
  }

  checkCaptchaBypass = async () => {
    // bypass captcha for dev
    const { checkCaptcha } = this.props;
    if (
      (typeof window !== 'undefined' && typeof window.location.hostname !== 'undefined') && (
        window.location.hostname.includes('local.f1payments.') ||
        window.location.hostname.includes('local.corviapay.') ||
        window.location.hostname.includes('.test.') ||
        window.location.hostname.includes('.dev.')
      )
    ) {
      await this.setState({ captchaValid: true });
      checkCaptcha(true, 'captchaCodeForTest');
      return true;
    }
    return false;
  }

  setId = () => {
    setTimeout(async () => {
      const captchaIframe = await document.getElementsByTagName('iframe')[0];
      if (captchaIframe) {
        captchaIframe.id = 'captchaIframe';
      }
      await this.updateState({ loader: false });
    }, 1000);
  }

  verifyCaptcha = (code) => {
    const { checkCaptcha } = this.props;
    this.updateState({ captchaValid: true });
    checkCaptcha(true, code);
  }

  resetCaptcha = async () => {
    const { checkCaptcha } = this.props;
    if (this.captcha !== null) {
      this.captcha.reset();
      const valid = await this.checkCaptchaBypass();
      if (!valid) {
        await this.updateState({ captchaValid: false });
        checkCaptcha(false, '');
      }
    }
  }

  render () {
    const { siteKey, loader } = this.state;
    return (
      <div
        id="captcha"
        role="article"
        aria-label="Captcha"
        style={{
          ...shield(),
          padding: '10px 10px 10px 50px',
          transform: 'scale(100%)',
          WebkitTransform: 'scale(100%)',
          transformOrigin: '0 0',
          WebkitTransformOrigin: '0 0',
          backgroundColor: '#d3d3d3',
          borderRadius: 'var(--radius-main)',
          minHeight: '40px',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Spinner loading={loader} />
        {siteKey && (
          <ReCAPTCHA
            ref={this.setCaptchaRef}
            sitekey={siteKey}
            theme="light"
            size="normal"
            onChange={this.verifyCaptcha}
            onExpired={this.resetCaptcha}
            onErrored={this.resetCaptcha}
          />
        )}
      </div>
    );
  }
}

Captcha.propTypes = {
  handleApiError: PropTypes.func,
  checkCaptcha: PropTypes.func,
  codeValid: PropTypes.bool
};

Captcha.defaultProps = {
  handleApiError: () => {},
  checkCaptcha: () => {},
  codeValid: false
};

export default Captcha;
