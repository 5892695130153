import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from './_helpers';
import { checkbox, checkboxListStyle, input } from './_styles';
import { ToolTip } from './ToolTip';
import { Checkbox } from './Checkbox';
import {
  ErrorBox
} from '../css/_styledFormComponents';

export class CheckboxList extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { checkedItems } = this.props;
    this.state = {
      checkedMap: this.formatCheckedItems(checkedItems),
      fileCounter: 0,
      errorText: '',
      isValid: null
    };
  }

  componentDidMount () {
    this.mounted = true;
    const { checkedItems, validationActivated } = this.props;
    const formatted = this.formatCheckedItems(checkedItems);
    const hasItemsChecked = !isEmpty(formatted)
      ? Object.values(formatted).some(isChecked => isChecked)
      : false;
    if (validationActivated || hasItemsChecked) {
      this.setValid(formatted);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { checkedItems } = this.props;
    const { checkedMap } = this.state;
    if (checkedItems !== prevProps.checkedItems &&
     !isEmpty(checkedItems) && isEmpty(checkedMap)) {
      const formatted = this.formatCheckedItems(checkedItems);
      this.updateState({
        checkedMap: formatted
      }, () => this.setValid(formatted));
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  setValid = (checkedItems) => {
    // if field is required, verify that something in the list was checked.
    const { isValid } = this.state;
    const { required, validationActivated } = this.props;
    const valid = required
      ? !isEmpty(Object.values(checkedItems)?.filter(item => item !== false))
      : true;
    if (required && (isValid || validationActivated) && !valid) {
      // it WAS valid, but now it isn't, set an error state if a selection is Required
      this.updateState({
        errorText: 'A selection is required'
      });
    }
    this.updateState({
      isValid: valid,
      ...(valid && { errorText: '' })
    });
  }

  formatCheckedItems = (checkedItems) => {
    if (!isEmpty(checkedItems)) {
      if (Array.isArray(checkedItems)) {
        // callback passes data as an array, so we need to convert the data
        // back to an object of key/value pairs for preloading values to work
        return checkedItems.reduce((acc, item) => ({ ...acc, ...item }), {});
      }
      return checkedItems;
    }
    return {};
  }

  handleCheckbox = (id, value) => {
    const { checkedMap } = this.state;
    const { customSelections } = this.props;
    const customMatch = customSelections?.find(item => item.value === id);
    if (id === 'ALL') {
      this.handleAll(value);
    } else if (!isEmpty(customMatch)) {
      const updateGroups = Object.entries(checkedMap)?.reduce((acc, [mapKey, mapValue]) => ({
        ...acc,
        [mapKey]: mapValue === id
      }), {});
      const newCheckedMap = {
        ...updateGroups,
        [customMatch.value]: value
      };
      this.updateState({
        checkedMap: newCheckedMap
      }, this.handleCallback);
    } else { // not clicking a custom selection
      const resetCustoms = customSelections?.reduce(
        (acc, sel) => ({ ...acc, [sel.value]: false }), {}
      ) || {};
      const allValue = id !== 'ALL' && checkedMap.ALL === true && value === false ? { ALL: false } : {};
      this.updateState(prevState => ({
        ...prevState,
        fileCounter: value ? prevState.fileCounter + 1 : prevState.fileCounter - 1,
        checkedMap: {
          ...prevState.checkedMap, // retain prev state's checked boxes
          ...resetCustoms, // reset any custom selections that may be checked
          [id]: value,
          ...allValue
        }
      }), this.handleCallback);
    }
    this.setValid({
      ...checkedMap,
      [id]: value
    });
  };

  handleCallback = () => {
    const {
      callback,
      list,
      id,
      required
    } = this.props;
    const { checkedMap, fileCounter } = this.state;
    const checked = Object.entries(checkedMap)
      .filter(([key1, value1]) => value1 && key1 !== 'ALL') // first filter on 'true' (checked) items only
      .reduce((acc, [key, value]) => {
        const match = list
          .find(item => item.value === key); // get the match from the full list of items
        return acc.concat({
          [match?.value]: true
        });
      }, []);
    const valid = required
      ? !isEmpty(Object.values(checkedMap)?.filter(item => item !== false)) : true;
    callback(checked, id, checkedMap, fileCounter, valid);
  }

  handleAll = (allChecked) => {
    // currently handle all only works correctly if you initially send in all your checklist items
    // in checkedItems. That initializes checkedMap to contain all elements, they can all be false
    // if needed
    const {
      callback,
      id,
      list,
      required
    } = this.props;
    const newCheckMap = list.reduce((acc, listFile) => {
      acc[listFile.value] = allChecked;
      return acc;
    }, { ALL: allChecked });
    const fileCounter = Object.entries(newCheckMap)
      .filter(([key, itemChecked]) => key !== 'ALL')
      .reduce((acc, [key, itemChecked]) => (itemChecked ? acc + 1 : acc), 0);
    const checked = allChecked ? list.map(item => ({ [item.value]: true })) : [];
    this.updateState({ checkedMap: newCheckMap, fileCounter });
    const valid = required
      ? !isEmpty(Object.values(checked)?.filter(item => item !== false)) : true;
    callback(checked, id, newCheckMap, fileCounter, valid);
  }

  render () {
    const {
      containerStyle,
      wrapperStyle,
      checkboxStyle,
      subTitleStyle,
      subTitleText,
      countStyles,
      innerWrapperStyle,
      countText,
      selectAll,
      allLabelText,
      label,
      list,
      labelPos,
      required,
      disabled,
      height,
      tooltip,
      boxStyle,
      noBoxBorder,
      className,
      id,
      tooltipWidth,
      infoTipDisplay
    } = this.props;
    const {
      checkedMap,
      fileCounter,
      errorText,
      isValid
    } = this.state;
    const defaultClassName = required ? 'checkboxList required' : 'checkboxList';
    const customClassName = !isEmpty(className) ? ` ${className}` : '';
    return (
      <div
        id={id}
        data-testid={id}
        className={`${defaultClassName}${customClassName}`}
        style={{
          ...checkboxListStyle.container,
          ...(boxStyle === 'inside' ? checkboxListStyle.innerWrap : input.wrap),
          ...((boxStyle === 'inside' && isValid) && { backgroundColor: 'var(--color-healthy-bg)' }),
          ...((boxStyle === 'inside' && !isEmpty(errorText)) && { backgroundColor: 'var(--color-warning-bg)' }),
          ...(disabled && input.innerWrapDisabled),
          ...(noBoxBorder && { border: '0 0 0' }),
          ...containerStyle
        }}
      >
        { label && (
          <label
            className="checkboxListLabel"
            id={`label_${label.toLowerCase().replace(/ /g, '_')}`}
            style={{
              ...(labelPos === 'right' && checkboxListStyle.labelRight),
              ...((labelPos === 'left' && height) && {
                ...checkboxListStyle.labelLeft,
                lineHeight: `${height}px`
              }),
              ...(boxStyle === 'inside' ? {
                ...input.labelInside,
                ...(labelPos === 'top' && { flex: '100%' })
              } : input.label),
              paddingBottom: '8px',
              ...(disabled && checkbox.label_disabled)
            }}
            htmlFor={`input_${label.toLowerCase().replace(/ /g, '_')}`}
          >
            { required && (
              <span style={input.label_required}>* </span>
            )}
            {label}
            {tooltip && (
              <ToolTip
                infoTip
                infoTipDisplay={infoTipDisplay}
                {...tooltipWidth && { width: tooltipWidth }}
              >
                {tooltip}
              </ToolTip>
            )}
          </label>
        )}
        <div
          id={`checkboxListBox_${label.toLowerCase().replace(/ /g, '_')}`}
          className={required ? 'checkboxListBox required' : 'checkboxListBox'}
          style={{
            ...wrapperStyle,
            ...(disabled && input.innerWrapDisabled)
          }}
        >
          {subTitleText && (
            <div style={subTitleStyle}>{subTitleText}</div>
          )}
          <div
            id={`checkboxListBoxInnerAll_${label.toLowerCase().replace(/ /g, '_')}`}
            style={{
              ...wrapperStyle,
              ...innerWrapperStyle
            }}
          >
            {selectAll && (
              <Checkbox
                labelStyle={{ fontWeight: '800' }}
                {...checkboxStyle}
                key="ALL"
                name="ALL"
                id="ALL"
                label={allLabelText || 'ALL'}
                value="ALL"
                checked={checkedMap.ALL}
                callback={this.handleCheckbox}
                disabled={disabled}
                required={false}
                boxStyle={boxStyle}
              />
            )}
          </div>
          <div
            id={`checkboxListBoxInner_${label.toLowerCase().replace(/ /g, '_')}`}
            style={{
              ...wrapperStyle,
              ...innerWrapperStyle
            }}
          >
            {list.map(item => (
              <Checkbox
                {...checkboxStyle}
                labelStyle={{
                  ...(checkedMap[item.value] && { fontWeight: '800' }),
                  ...checkboxStyle?.labelStyle
                }}
                key={item.value}
                name={item.value}
                id={item.value}
                label={item.title}
                value={item.value}
                tooltip={item.tooltip}
                checked={checkedMap[item.value]}
                callback={this.handleCheckbox}
                disabled={disabled}
                required={false}
                boxStyle={boxStyle}
              />
            ))}
          </div>
          {countText && (
            <div style={countStyles}>
              {`${fileCounter} ${countText} Selected`}
            </div>
          )}
        </div>
        <ErrorBox
          error={!isEmpty(errorText)}
          $boxStyle={boxStyle}
        >
          {errorText}
        </ErrorBox>
      </div>
    );
  }
}

CheckboxList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]),
  checkboxStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  containerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  countStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitleStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  innerWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  callback: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string,
  labelPos: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  tooltip: PropTypes.string,
  boxStyle: PropTypes.string,
  noBoxBorder: PropTypes.bool,
  className: PropTypes.string,
  countText: PropTypes.string,
  subTitleText: PropTypes.string,
  selectAll: PropTypes.bool,
  allLabelText: PropTypes.string,
  id: PropTypes.string,
  tooltipWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  infoTipDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  customSelections: PropTypes.oneOfType([PropTypes.array]),
  validationActivated: PropTypes.bool
};

CheckboxList.defaultProps = {
  list: [],
  checkboxStyle: {},
  containerStyle: {},
  wrapperStyle: {},
  countStyles: {},
  subTitleStyle: {},
  innerWrapperStyle: {},
  callback: () => {},
  options: {},
  label: '',
  labelPos: '',
  required: false,
  disabled: false,
  height: 30,
  tooltip: null,
  boxStyle: null,
  noBoxBorder: false,
  className: null,
  countText: '',
  subTitleText: '',
  selectAll: false,
  allLabelText: 'ALL',
  id: null,
  tooltipWidth: null,
  infoTipDisplay: {},
  checkedItems: {},
  customSelections: [],
  validationActivated: false
};

export default CheckboxList;
